.banner {
  background-color: #ffd700;
  color: #000;
  padding: s(medium);
  text-align: center;
  font-size: s(small);
  font-weight: bold;

  a {
    color: #000;
    text-decoration: underline;
  }

  & .t-lead {
    font-family: Avenir, Arial, sans-serif;
  }
}

.intro {
  &__header {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: s(x-large);
    margin-right: s(medium);
  }
  &__title {
    flex: 1;
  }

  &__body {
    margin-top: s(small);
    opacity: 0.8;
  }
}
