video,
.shadow {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2), 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.l-page {
  margin-top: $header-height;
  &:not(.l-page--spaceless) {
    min-height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex: 1;
  }

  &__footer {

  }

  @include breakpoint(mobile) {
    margin-top: $header-height-mobile;
    min-height: auto;
  }
  &--spaceless {
    margin-top: 0;
    @include breakpoint (mobile) {
      margin-top: 0;
    }
  }
}

.video--no-shadow {
  box-shadow: none;
  justify-content: center;
}



.download-badge {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include breakpoint (mobile) {
    flex-direction: column;
  }
}

.download-badge__button {
  height: 100px;
  display: block;
  margin-top: 4.5rem;

  @include breakpoint (mobile) {
    margin: 0;
    height: 80px;
  }
}

.download-badge__legal-notice {
    @include type(caption);
    opacity: .5;

    @include breakpoint (mobile) {
      font-size: .7rem;
    }
  }


img.download-badge__img {
  height: 100%;
  width: auto;
  margin-top: 0;
}
