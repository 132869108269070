$team-item-name-margin-top: s(small);
$item-item-space-between: s(medium);
$item-item-space-bpttom: s(medium);
$item-min-width: 200px;
$team-members-spacing: s(x-large);

.team {
  position: relative;
  margin: auto;
  text-align: center;
}

.team__members {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: $team-members-spacing 0 0;
  padding: 0;
}

.team__item {
  text-align: center;
  padding-left: $item-item-space-between/2;
  padding-right: $item-item-space-between/2;
  margin-bottom: $item-item-space-bpttom;
  min-width: $item-min-width;
  max-width: 100%;


  &-name {
    margin-top: $team-item-name-margin-top;
  }
}

.team.c-color-aroov--color blockquote {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}
