$header-height: 60px;
$header-height-mobile: 60px;
$image-height: 35px;
$image-height-mobile: 25px;

.affix-header {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  opacity: 1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    height: $header-height;
    overflow: hidden;

    @include breakpoint(mobile) {
      height: $header-height-mobile;
    }
  }

  &__go-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: s(medium);
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &__arrows {
    position: absolute;
    top: 0;
    bottom: 0;
    right: s(medium);
    display: flex;
    align-items: center;
  }

  &__go-prev {
    transform: rotate(180deg) !important;
    margin-right: s(medium);
  }

  &__logo {
    display: block;
    width: auto !important;
    height: 32px !important;

    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform 100ms ease-in-out;

    svg {
      color: inherit;
      width: 100%;
    }

    path {
      fill: currentColor;
    }
  }

  &__logo-container,
  &__title-container {
    transition: transform .2s ease-in-out, opacity .3s ease-in-out;
  }

  &__logo-container {
    position: relative;
    transform: translate(0, 0);
  }

  &__title-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    opacity: 0;
  }

  &--scrolled &__logo-container {
    transform: translate(0, -100%);
    opacity: 0;
  }

  &--scrolled &__title-container {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  &__link {
    display: inline-block;
    color: inherit;
    width: 100%;
  }
}
