body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

hr.hr--light {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
