$item-spacing: s(normal);
$icon-spacing: s(normal);
$icon-size: 1.2rem;

.list {
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.list__item {
  @include type('paragraph-sans');
  display: flex;
  padding-top: $item-spacing;
  padding-bottom: $item-spacing;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: currentColor;
      opacity: .2;
      content: '';
    }
  }
}

.list__item-icon {
  display: none;

  svg {
    display: inline-block;
    vertical-align: middle;

    width: 1em;
  }
}

.list--dark .list__item-icon svg {
  // @TODO: add propert color as option
  fill: c(client, paycall-violet);
  stroke: c(client, paycall-violet);
  //opacity: .6;
}

.list {
  &--tick {
    stroke: currentColor;

    .list__item-icon {
      display: inline-block;
    }
  }
  &--number {
    counter-reset: number-counter;

    .list__item {
      counter-increment: number-counter;
    }

    .list__item:before {
      content: counter(number-counter) '.';
      vertical-align: top;
    }
  }

  &--tick,
  &--number {
    .list__item-content {
      padding-left: $icon-spacing;
    }

    .list__item {
      &::after {
        display: none;
      }
    }
  }
}
