@import
    "sensible/mediaqueries"
  , "sensible/grid"
  , "sensible/responsive-visibility"
  ;

/**
 * Define the breakpoints for vendor/sensible/mediaqueries
 * They are defined the mobile first way
 */

$breakpoints: (
    "mobile"              : "only screen and (max-width:740px)",
    "tablet"              : "only screen and (max-width:1050px)",
    "desktop"             : "only screen and (min-width:1051px)",
    "print"               : "print"
);


$grid-gutter: 2rem;

@include sensibleGrid (
  $modern:               true,
  $gutter:               $grid-gutter,
  $gutter-type:          padding,
  $gutter-direction:     left,
  $gutter-modifiers:     (
    "narrow" : 1rem,
    "collapsed": 0
  ),
  $base-font-size:       1rem,
  $slug:                 "l-",
  $push-classes:         false,
  $grid-widths:          (
    "one-whole"       : 100%,
    "one-half"        : 50%,
    "one-quarter"     : 25%,
    "three-quarters"  : 75%,
    "one-third"       : 33.333%,
    "two-thirds"      : 66.666%,
    "one-fifth"       : 20%,
    "two-fifths"      : 40%,
    "three-fifths"    : 60%,
    "four-fifths"     : 80%,
    "one-sixth"       : 16.666%,
    "five-sixths"     : 83.333%
  ),
  $grid-breakpoints:  "tablet" "mobile"
);

.l-grid--v-centered {
  align-items: center;
}

.l-grid--vr > .l-grid-cell {
  margin-top: $grid-gutter / 2;
  margin-bottom: $grid-gutter / 2;
}
.l-grid--vr.l-grid--narrow > .l-grid-cell {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@include responsive-visibility("desktop" "tablet" "mobile", true);
