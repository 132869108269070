$testimonial-callout-margin-top: 2rem;
$testimonial-avatar-height: 8rem;
$testimonial-section-padding-top: 4.5rem;
$testimonial-author-margin-top: size(medium);

/* Avatar positioned half his height and taking into consideration section padding top */
$testimonial-avatar-position-half-height: $testimonial-avatar-height/2 + $testimonial-section-padding-top;

.testimonial {
  &__avatar {
    position: relative;
    margin: - $testimonial-avatar-position-half-height auto 0;
    text-align: center;
  }

  &__callout {
    margin-top: $testimonial-callout-margin-top;
  }

  &__author {
    margin-top: $testimonial-author-margin-top;
    text-align: center;
  }

  &__name {
    display: block;
  }
}
