$device-margin-bottom: 3.5em;
$separator-width: 100px;
$separator-height: 2px;
$separator-margin: 3.5em;
$slogan-margin-bottom: 6em;

.project-header {
  position: relative;
  margin: auto;

  text-align: center;
}

.project-header__highlight {
  position: relative;
  margin-bottom: $device-margin-bottom;
}

.project-header__background {
  position: absolute;
  height: 85%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Devices
.project-header__device {
  position: relative;
  margin: auto auto 0 auto;
  padding-top: 10%;
}

// Device specific positioning
.project-header__device.iphone-6s {
  width: 25%;
  min-width: 150px;
  max-width: 250px;
}
.project-header__device.dell-hd {
  width: 70%;
  min-width: 150px;
  max-width: 840px;
}
.project-header__device.macbook-air {
  width: 70%;
  min-width: 150px;
  max-width: 840px;
}

.project-header__device.ipad-air2 {
  width: 70%;
  max-width: 840px;
  min-width: 150px;
}

.project-header__separator {
  width: $separator-width;
  height: $separator-height;
  margin: $separator-margin auto;

  background-color: c(neutral, silver);
}

.project-header__slogan {
  margin-bottom: $slogan-margin-bottom;
}
