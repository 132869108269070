$icon-link-space-between: s(small);

.icon-link {
  text-decoration: none;
  opacity: 1;
  transition: 0.4s opacity ;
  will-change: opacity;
  transform: translate3d(0, 0, 0);
  color: inherit;

  display: flex;
  align-items: center;
  justify-content: center;


  &:hover {
    opacity: .6;
  }
  &__icon {
    width: s(medium);
    height: s(medium);
  }

  &__text {
    margin-left: $icon-link-space-between;
    // TODO:

  }
  &--hide-text-on-mobile &__text {
    @include breakpoint(mobile) {
      display: none;
    }
  }

  &--rtl {
    flex-direction: row-reverse;
  }

  &--rtl &__text {
    margin-left: 0;
    margin-right: $icon-link-space-between;
  }

  &--text-only &__text {
    margin-left: 0;
    margin-right: 0;
  }
}
