$top-spacing: s(x-large);
$bottom-spacing: s(x-large);
$item-line-spacing: s(medium);
$line-width: 50px;
$line-top-spacing: s(x-large);
$line-bottom-spacing: 50px;
$image-height: 50px;

.footer {
  padding: $top-spacing 0px $bottom-spacing 0px;
  text-align: center;

  &__logo {
    display: block;
    width: auto !important;
    height: 40px !important;

    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform 100ms ease-in-out;

    g {
        fill: white;
    }
  }

  &__info {

  }

  &__info-block {
    display: flex;
    align-items: center;
    justify-content: center;

    &--links {

      @include breakpoint(mobile) {
        flex-direction: column;
      }
    }

    &--icons {
      margin-top: s(medium);
    }

    &--logo {
      margin-top: s(large);
    }
  }



  &__link {
    display: inline-block;
    margin-bottom: $item-line-spacing;




    .icon-link__text {
      color: c(neutral, white);
    }


    svg {
      fill: c(neutral, white);
    }
  }

  &__info-block--icons &__link:not(:last-child) {
    margin-right: s(large);

  }

  &__info-block--links &__link:not(:last-child) {
    margin-right: s(large);
    @include breakpoint(mobile) {
      margin-right: 0;
    }
  }
}


.hr--short {
    width: 48px;
    border: 0;
    outline: 0;
    border-bottom: 1px solid white;

    margin: 0 auto;
    display: inline-block;
}
