$hotspot-hotspot-radius: 1.25rem;
$hotspot-hotspot-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6);;
$hotspot-hotspot-popup-width: 15rem;
$hotspot-hotspot-popup-padding: 0.5rem;

$hotspot-hotspot-arrow-size: 0.5rem;
$hotspot-hotspot-arrow-border-default: $hotspot-hotspot-arrow-size solid transparent;
$hotspot-hotspot-arrow-distance-from-corner: 0.3rem;

$hotspot-hotspot-popup-distance-from-hotspot: $hotspot-hotspot-radius;

$hotspot-hotspot-popup-arrow-up-left-corner: translate(-$hotspot-hotspot-radius + $hotspot-hotspot-arrow-distance-from-corner, $hotspot-hotspot-radius + $hotspot-hotspot-popup-distance-from-hotspot);
$hotspot-hotspot-popup-arrow-up-right-corner: translate(-$hotspot-hotspot-radius  - $hotspot-hotspot-popup-width, $hotspot-hotspot-radius + $hotspot-hotspot-popup-distance-from-hotspot);
$hotspot-hotspot-popup-arrow-right-top-corner: translate(-$hotspot-hotspot-radius - $hotspot-hotspot-popup-width - $hotspot-hotspot-arrow-size - $hotspot-hotspot-popup-distance-from-hotspot, - $hotspot-hotspot-arrow-distance-from-corner);
$hotspot-hotspot-popup-arrow-left-top-corner: translate($hotspot-hotspot-popup-distance-from-hotspot - $hotspot-hotspot-arrow-size, - $hotspot-hotspot-arrow-distance-from-corner);
$hotspot-hotspot-popup-arrow-left-bottom-corner: translate($hotspot-hotspot-popup-distance-from-hotspot - $hotspot-hotspot-arrow-size, - $hotspot-hotspot-arrow-size);
$hotspot-hotspot-popup-arrow-right-bottom-corner: translate(- $hotspot-hotspot-popup-distance-from-hotspot  - $hotspot-hotspot-arrow-size -$hotspot-hotspot-radius - $hotspot-hotspot-popup-width, - $hotspot-hotspot-arrow-size);
$hotspot-hotspot-popup-arrow-down-left-corner: translate(-$hotspot-hotspot-radius + $hotspot-hotspot-arrow-distance-from-corner, - $hotspot-hotspot-popup-distance-from-hotspot - $hotspot-hotspot-arrow-size);
$hotspot-hotspot-popup-arrow-down-right-corner: translate(-$hotspot-hotspot-radius - $hotspot-hotspot-popup-width, - $hotspot-hotspot-popup-distance-from-hotspot - $hotspot-hotspot-arrow-size);


.hotspot {
  margin-top: s(large);
  display: flex;
  justify-content: center;
}

.hotspot__screenshot {
  position: relative;
  display: block;
}

.hotspot__image {
  max-width: 100%;
  margin: 0;
}

.hotspot__item {
  position: absolute;
}

.hotspot__item-dot {
  display: block;
  border-radius: 50%;
  width: $hotspot-hotspot-radius;
  height: $hotspot-hotspot-radius;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  box-shadow: $hotspot-hotspot-shadow;
}

.hotspot__item-popup {
  opacity: 0;
  text-align: left;
  width: $hotspot-hotspot-popup-width;
  padding: $hotspot-hotspot-popup-padding;
  z-index: -1;
}

.hotspot__item-arrow {
  display: inline-block;
  position: absolute;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-top: $hotspot-hotspot-arrow-border-default;
    border-right: $hotspot-hotspot-arrow-border-default;
    border-bottom: $hotspot-hotspot-arrow-border-default;
    border-left: $hotspot-hotspot-arrow-border-default;
  }
}

.hotspot__item-arrow-left:after {
  right: 100%;
  border-right-color: currentColor;
}

.hotspot__item-arrow-right:after {
  left: 100%;
  border-left-color: currentColor;
}

.hotspot__item-arrow-up:after {
  bottom: 100%;
  border-bottom-color: currentColor;
}

.hotspot__item-arrow-down:after {
  top: 100%;
  border-top-color: currentColor;
}

.hotspot__item-arrow-left-corner:after {
  left: $hotspot-hotspot-arrow-distance-from-corner;
}

.hotspot__item-arrow-right-corner:after {
  right: $hotspot-hotspot-arrow-distance-from-corner;
}

.hotspot__item-arrow-top-corner:after {
  top: $hotspot-hotspot-arrow-distance-from-corner;
}

.hotspot__item-arrow-bottom-corner:after {
  bottom: $hotspot-hotspot-arrow-distance-from-corner;
}

.hotspot__item-radio {
  visibility: hidden;
}

.hotspot__item-radio:checked ~ .hotspot__item-popup {
  opacity: 1;
  z-index: 2;
}

/**
 * Define the basic popup translations based on
 * the arrow direction and arrow corner position
 */
.hotspot__item-arrow-up.hotspot__item-arrow-left-corner {
  transform: $hotspot-hotspot-popup-arrow-up-left-corner;
}

.hotspot__item-arrow-up.hotspot__item-arrow-right-corner {
  transform: $hotspot-hotspot-popup-arrow-up-right-corner;
}

.hotspot__item-arrow-right.hotspot__item-arrow-top-corner {
  transform: $hotspot-hotspot-popup-arrow-right-top-corner;
}

.hotspot__item-arrow-left.hotspot__item-arrow-top-corner {
  transform: $hotspot-hotspot-popup-arrow-left-top-corner;
}

.hotspot__item-arrow-left.hotspot__item-arrow-bottom-corner {
  transform: $hotspot-hotspot-popup-arrow-left-bottom-corner;
}

.hotspot__item-arrow-right.hotspot__item-arrow-bottom-corner {
  transform: $hotspot-hotspot-popup-arrow-right-bottom-corner;
}

.hotspot__item-arrow-down.hotspot__item-arrow-left-corner {
  transform: $hotspot-hotspot-popup-arrow-down-left-corner;
}

.hotspot__item-arrow-down.hotspot__item-arrow-right-corner {
  transform: $hotspot-hotspot-popup-arrow-down-right-corner;
}
