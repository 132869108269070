@import 'modularscale-sass/stylesheets/modularscale';

/*
 * A modern theme for portfolio pages using sans serif typefaces
 * and a modular scale with fluid type. This theme also includes a
 * dark variation that can be used to emphasize a section.
 */

 $modularscale: (
 320px: (
   base: 17px,
   ratio: 1.2,
 ),
 1200px: (
   base: 18px,
   ratio: 1.35,
 ),
);

/*
* Don’t import any other fonts here, because they would be loaded on every page.
* Ideally, themes could be included in the head of a page to avoid
* loading global styles. By design, this is not possible because the "meta" block
* in layout templates will inject custom meta tags, such as a custom CSS file,
* before other styles. Fonts are loaded from the page layout for now.

* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700');

*/

.theme--modern .narrative {

  font-size: ms(0);

  h1, h2, h3, h4, h5, h6, p, ul, li, blockquote {
    font-family:  "Avenir Next", "Avenir", "Helvetica Neue", sans-serif;
  }

  /* Remove element specific spacing */
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  /*
   * Tags
   */

  h1 {
    @include ms-respond(font-size, 4, $modularscale);
    text-align: center;
    line-height: 1.25;
    margin-top: 15%;
    font-weight: 700;
  }

  h2 {
    @include ms-respond(font-size, 2, $modularscale);
    font-weight: 700;
  }

  h3 {
    @include ms-respond(font-size, 1, $modularscale);
    font-weight: 600;
  }

  h4 {
    @include ms-respond(font-size, 0, $modularscale);
    font-weight: 600;
  }

  p, ul, li, blockquote {
    @include ms-respond(font-size, 0, $modularscale);
    line-height: 1.5;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  strong {
    font-weight: 600;
  }

  blockquote {
    border-left: 8px solid rgba(0, 0, 0, 0.1);
    margin: 1.5em 0 1.5em 0;
    padding-left: 1rem;
    opacity: 0.7;
  }

  figcaption {
    text-align: center;
  }

  hr {
    margin: 3rem 0;
  }

  /*
   * Spacing
   */

  /* Specific styles for project title + subtitle combo */
  h1 + h2 {
    margin-top: 0.5em;
    text-align: center;
    font-weight: normal;
    opacity: 0.5;
  }

  h2 + h3 {
    margin-top: 2rem;
  }

  figure + p,
  p + figure {
    margin-top: 1.5rem;
  }

  h3 + p,
  p + p {
    margin-top: 1em;
  }

  ul + h3,
  p + h3,
  figure + h3 {
    margin-top: 3rem;
  }
}

/* -------------------------------------------------------------------------- */


.theme--modern-dark .narrative {

  /*
   * Styles for a feature block that can be used to emphasize a section.
   * Applies a dark theme.
   */

   color: white;

  /* Control rendering for light text on dark background */
  -webkit-font-smoothing: antialiased;

  /*
   * Tags
   */

  h2 {

    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    text-align: left;

    @include ms-respond(font-size, 3, $modularscale);

    color: white;
    opacity: 1;
  }

  p {
    opacity: 0.8;
    margin-top: 0;
  }

  hr {
    background: rgba(255, 255, 255, 0.25);
    border: 0;
    display: block;
    width: 100%;
    height: 1px;
    margin: 40px 0;
    padding: 0;
  }

  /*
   * Spacing
   */

   /* Tweak for multi-column baseline alignment */
   h4 + p {
     margin-top: 0.2rem;
   }

   /*
    * Mobile layout changes
    */

   @media (max-width: 480px) {

     /* Change side-by-side grid layout to a stack */
     .l-grid {
       display: block;
     }

     .l-one-half + .l-one-half {
       margin-top: 1.5rem;
     }

     .l-one-half {
       width: 100%;
     }
   }
}
