$section-spacing-vertical: 4.5rem;
$section-spacing-vertical-mobile: 2.5rem;

$section-section-number-divider-spacing-horizontal: s(small);
$section-section-number-margin: 1rem;

.section {

  padding-top: $section-spacing-vertical;
  padding-bottom: $section-spacing-vertical;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include breakpoint(mobile) {
    padding-top: $section-spacing-vertical-mobile;
    padding-bottom: $section-spacing-vertical-mobile;
  }


  &__section-title {
    margin-bottom: $section-section-number-margin;

  }

  &__section-number {
    margin-right: s(small);
    // &:after {
    //   content: "";
    //   display: inline-block;
    //
    //   width: 1px;
    //   margin: 0 $section-section-number-divider-spacing-horizontal;
    //   background-color: currentColor;
    //   // transform: translateY(50%);
    //   // transform: skew(10deg);
    //   opacity: .8;
    // }
  }
}

.section-small {
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.aroov-research {
  position: relative;
  padding-top: 30vh;
  padding-bottom: 30vh;

  .l-container {
    position: relative;
    z-index: 1;
  }

  .narrative p {
    font-size: 1.2rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../content/images/aroov/research.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: .2;
  }
}
