$item-spacing: 5px;
$button-vertical-spacing: 80px;
$button-width: 40%;

.list-pageable {
  text-align: center;

  &:not(.is-expanded) .list-pageable__hidden-projects {
    display: none;
  }

  &__show-more {
    margin: $button-vertical-spacing 0px;
    width: $button-width;
    margin-right: auto;
    margin-left: auto;

    &.is-hidden {
      display: none;
    }
  }
}
