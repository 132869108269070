$timeline-color: c(client, local);
$timeline-color-lighten: lighten($timeline-color, 10%);

$timeline-background-color: c(neutral, white);

$timeline-distance-to-content: size(large);
$timeline-distance-to-content-mobile: size(medium);
$timeline-distance-between-items: 4rem;

$timeline-line-width: 0.075rem;
$timeline-cursor-line-width: 0.15rem;

$timeline-cursor-dot-size: 1.5rem;
$timeline-cursor-dot-width: 0.1rem;
$timeline-item-dot-size:  1rem;
$timeline-cursor-line-height: 0.5rem;

$timeline-spacing-between: 6rem;


.timeline {
  position: relative;
  padding-left: $timeline-distance-to-content;

  @include breakpoint(mobile) {
    padding-left: $timeline-distance-to-content-mobile;
  }
   /* this is the vertical line */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: $timeline-line-width;
    background: $timeline-color-lighten;
  }

  &__cursor {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &__cursor-line {
    position: absolute;
    top: ($timeline-cursor-dot-size / 2);
    left: 0;
    width: $timeline-cursor-line-width;
    bottom: 0;
    transform: scaleY(0);
    background: $timeline-color;
    margin-left: -($timeline-cursor-dot-width / 2);
    transform-origin: top center;
    transition: transform 16ms ease;
    will-change: transform;
  }

  &__cursor-dot {
    position: absolute;
    top: 0;
    left: - $timeline-item-dot-size / 2;
    width: $timeline-item-dot-size;
    height: $timeline-item-dot-size;
    border-radius: 50%;
    background: $timeline-color;
    will-change: transform;
  }
}

.timeline__item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $timeline-distance-between-items;
  }

  &-content {
    align-items: center;
    display: flex;
    @include breakpoint(mobile) {
      display: block;
    }
  }

  &-content-cell {
    flex: 1;
  }


  &-content-cell--image {
    text-align: center;
  }

  &-dot {
    position: absolute;
    top: 50%;
    left: 0;
    width: $timeline-cursor-dot-size;
    height: $timeline-cursor-dot-size;
    border: ($timeline-cursor-dot-width / 2) solid $timeline-color-lighten;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    margin-left: - $timeline-distance-to-content;
    @include breakpoint(mobile) {
      margin-left: - $timeline-distance-to-content-mobile;
    }
    background-color: $timeline-background-color;
    z-index: 1;

    &:before {
      will-change: opacity;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: $timeline-item-dot-size;
      height: $timeline-item-dot-size;
      opacity: 0;
      border-radius: 50%;
      background: $timeline-color;
      transform: translate(-50%, -50%);
      transition: opacity .2s ease-in-out;
    }

    &.is-active {
      border: $timeline-cursor-dot-width solid $timeline-color-lighten;
    }

    &.is-active:before {
      opacity: 1;
    }

  }
  &:first-child &-dot {
    top: 0;
    transform: translate(-50%, 0);
    border: $timeline-cursor-dot-width solid $timeline-color-lighten;
  }

  &:last-child &-dot {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0);
  }
}

.timeline--middle {
  padding-left: 0;
  @include breakpoint(mobile) {
    padding-left: $timeline-distance-to-content-mobile;
  }

  &:before,
  .timeline__cursor,
  .timeline__item-dot {
    left: 50%;
    @include breakpoint(mobile) {
      left: 0;
    }

  }

  .timeline__item-dot {
    margin-left: 0;
    @include breakpoint(mobile) {
      margin-left: - $timeline-distance-to-content-mobile;
    }
  }

  .timeline__item:nth-child(even) .timeline__item-content-cell--text {
    order: -1;
  }
}

.timeline__item-content-cell--image > img {
  max-width: 100%;
}

.timeline__item {
  &-content-cell--image {
    padding-right: $timeline-spacing-between/2;
    @include breakpoint(mobile) {
      padding-right: 0;
    }
  }

  &-content-cell--text {
    padding-left: $timeline-spacing-between/2;
    @include breakpoint(mobile) {
      padding-left: 0;
    }
  }

  .timeline--middle &:nth-child(even) {
    .timeline__item-content-cell--image {
      padding-right: 0;
      padding-left: $timeline-spacing-between/2;
      @include breakpoint(mobile) {
        padding-left: 0;
      }
    }

    .timeline__item-content-cell--text {
      padding-left: 0;
      padding-right: $timeline-spacing-between/2;
      @include breakpoint(mobile) {
        padding-right: 0;
      }
    }
  }

}
