@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400italic,700,700italic);
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

hr.hr--light {
  border-top: 1px solid rgba(255, 255, 255, 0.1); }

.js-breakpoint:after,
.js-breakpoint {
  content: '{"mobile-portrait":"only screen and (max-width:425px)","mobile":"only screen and (max-width:740px)","not-mobile":"only screen and (min-width:741px)","only-mobile":"only screen and (max-width:740px)","tablet-portrait":"only screen and (max-width:850px)","tablet":"only screen and (max-width:1050px)","only-tablet":"only screen and (min-width:741px) and (max-width:1051px)","not-tablet":"only screen and (min-width:1051px)","desktop":"only screen and (min-width:1051px)","only-desktop":"only screen and (min-width:1051px)","retina":"only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5)","highres":"print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)","print":"print"}';
  font-family: '{"mobile-portrait":"only screen and (max-width:425px)","mobile":"only screen and (max-width:740px)","not-mobile":"only screen and (min-width:741px)","only-mobile":"only screen and (max-width:740px)","tablet-portrait":"only screen and (max-width:850px)","tablet":"only screen and (max-width:1050px)","only-tablet":"only screen and (min-width:741px) and (max-width:1051px)","not-tablet":"only screen and (min-width:1051px)","desktop":"only screen and (min-width:1051px)","only-desktop":"only screen and (min-width:1051px)","retina":"only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5)","highres":"print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)","print":"print"}'; }

/**
 * remove removes element from list returns the others
 */
/**
 * mixin responsive-visibility
 * $visibility-breakpoints {[list]} list of breakpoints you want to interact with
 * $overwrite {[boolean]} if set true will generate classes that overwrite what ever visibility is active on that element
 *
 * exact:
 * @include responsive-visibility("only-mobile" "only-tablet" "only-desktop", true);
 *
 * up:
 * @include responsive-visibility("mobile" "tablet" "desktop", false);
 */
/**
 * Define the breakpoints for vendor/sensible/mediaqueries
 * They are defined the mobile first way
 */

.l-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -2rem; }
  
  .l-grid .l-grid-cell {
    padding-left: 2rem;
    display: block; }
  
  .l-grid--narrow {
    margin-left: -1rem; }
    
    .l-grid--narrow .l-grid-cell {
      padding-left: 1rem; }
  
  .l-grid--collapsed {
    margin-left: 0; }
    
    .l-grid--collapsed .l-grid-cell {
      padding-left: 0; }
  
  .l-grid--gapped > .l-grid-cell {
    padding-bottom: 2rem; }


.l-grid-cell {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box; }


.l-one-whole {
  width: 100%; }


.l-one-half {
  width: 50%; }


.l-one-quarter {
  width: 25%; }


.l-three-quarters {
  width: 75%; }


.l-one-third {
  width: 33.333%; }


.l-two-thirds {
  width: 66.666%; }


.l-one-fifth {
  width: 20%; }


.l-two-fifths {
  width: 40%; }


.l-three-fifths {
  width: 60%; }


.l-four-fifths {
  width: 80%; }


.l-one-sixth {
  width: 16.666%; }


.l-five-sixths {
  width: 83.333%; }

@media only screen and (max-width: 1050px) {
  
  .l-one-whole--tablet {
    width: 100%; }
  
  .l-one-half--tablet {
    width: 50%; }
  
  .l-one-quarter--tablet {
    width: 25%; }
  
  .l-three-quarters--tablet {
    width: 75%; }
  
  .l-one-third--tablet {
    width: 33.333%; }
  
  .l-two-thirds--tablet {
    width: 66.666%; }
  
  .l-one-fifth--tablet {
    width: 20%; }
  
  .l-two-fifths--tablet {
    width: 40%; }
  
  .l-three-fifths--tablet {
    width: 60%; }
  
  .l-four-fifths--tablet {
    width: 80%; }
  
  .l-one-sixth--tablet {
    width: 16.666%; }
  
  .l-five-sixths--tablet {
    width: 83.333%; } }

@media only screen and (max-width: 740px) {
  
  .l-one-whole--mobile {
    width: 100%; }
  
  .l-one-half--mobile {
    width: 50%; }
  
  .l-one-quarter--mobile {
    width: 25%; }
  
  .l-three-quarters--mobile {
    width: 75%; }
  
  .l-one-third--mobile {
    width: 33.333%; }
  
  .l-two-thirds--mobile {
    width: 66.666%; }
  
  .l-one-fifth--mobile {
    width: 20%; }
  
  .l-two-fifths--mobile {
    width: 40%; }
  
  .l-three-fifths--mobile {
    width: 60%; }
  
  .l-four-fifths--mobile {
    width: 80%; }
  
  .l-one-sixth--mobile {
    width: 16.666%; }
  
  .l-five-sixths--mobile {
    width: 83.333%; } }

.l-grid--v-centered {
  align-items: center; }

.l-grid--vr > .l-grid-cell {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.l-grid--vr.l-grid--narrow > .l-grid-cell {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

@media only screen and (min-width: 1051px) {
  .is-visible-desktop {
    display: block !important;
    visibility: visible; }
    .is-visible-desktop--inline {
      display: inline; }
    .is-visible-desktop--inline-block {
      display: inline-block; }
  .is-hidden-desktop, .is-visible-tablet, .is-visible-mobile {
    display: none !important;
    visibility: hidden; }
    .is-hidden-desktop--inline, .is-visible-tablet--inline, .is-visible-mobile--inline {
      display: none !important; }
    .is-hidden-desktop--inline-block, .is-visible-tablet--inline-block, .is-visible-mobile--inline-block {
      display: none !important; } }

@media only screen and (max-width: 1050px) {
  .is-visible-tablet {
    display: block !important;
    visibility: visible; }
    .is-visible-tablet--inline {
      display: inline; }
    .is-visible-tablet--inline-block {
      display: inline-block; }
  .is-hidden-tablet, .is-visible-desktop, .is-visible-mobile {
    display: none !important;
    visibility: hidden; }
    .is-hidden-tablet--inline, .is-visible-desktop--inline, .is-visible-mobile--inline {
      display: none !important; }
    .is-hidden-tablet--inline-block, .is-visible-desktop--inline-block, .is-visible-mobile--inline-block {
      display: none !important; } }

@media only screen and (max-width: 740px) {
  .is-visible-mobile {
    display: block !important;
    visibility: visible; }
    .is-visible-mobile--inline {
      display: inline; }
    .is-visible-mobile--inline-block {
      display: inline-block; }
  .is-hidden-mobile, .is-visible-desktop, .is-visible-tablet {
    display: none !important;
    visibility: hidden; }
    .is-hidden-mobile--inline, .is-visible-desktop--inline, .is-visible-tablet--inline {
      display: none !important; }
    .is-hidden-mobile--inline-block, .is-visible-desktop--inline-block, .is-visible-tablet--inline-block {
      display: none !important; } }

html {
  font-size: 20px;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  color: c(neutral, black); }

.t-project-title {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.14; }
  @media only screen and (max-width: 740px) {
    .t-project-title {
      font-size: 2rem; } }

.t-project-lead {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.1rem;
  line-height: 1.19; }

.t-section-title {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 1.5;
  letter-spacing: 4px;
  text-transform: uppercase; }
  @media only screen and (max-width: 740px) {
    .t-section-title {
      font-size: 0.8rem; } }

.t-title {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2rem;
  line-height: 1.3; }
  @media only screen and (max-width: 740px) {
    .t-title {
      font-size: 1.5rem; } }

.t-lead {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.65; }
  @media only screen and (max-width: 740px) {
    .t-lead {
      font-size: 1rem; } }

.t-subtitle {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.3; }

.t-h4 {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.1rem;
  line-height: 1.3; }

.t-paragraph {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.65; }
  @media only screen and (max-width: 740px) {
    .t-paragraph {
      font-size: 0.9rem; } }

.t-paragraph-sans {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.65; }

.t-caption {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.4; }

.t-quote {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.8rem;
  line-height: 1.4; }
  @media only screen and (max-width: 740px) {
    .t-quote {
      font-size: 1.5rem; } }

.t-quote-small {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.6; }

@font-face {
  font-family: Avenir;
  font-weight: 400;
  font-style: normal;
  src: url("//static.ginetta.net/fonts/avenir-roman/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.eot");
  src: local("Avenir Roman"), url("//static.ginetta.net/fonts/avenir-roman/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.woff") format("woff"), url("//static.ginetta.net/fonts/avenir-roman/5ed4f98e-9044-4810-88ff-74d412c1351f.ttf") format("truetype"), url("//static.ginetta.net/fonts/avenir-roman/4cb16859-16ca-4342-b89c-292dc83266af.svg") format("svg"); }

@font-face {
  font-family: Avenir;
  font-weight: 400;
  font-style: italic;
  src: url("static.ginetta.net/fonts/avenir-black-oblique/8f21e618-9282-4df1-b556-73ee82bdd673.eot");
  src: local("Avenir Oblique"), url("static.ginetta.net/fonts/avenir-black-oblique/3695342c-b5e2-4010-b0d4-56f563465922.woff") format("woff"), url("static.ginetta.net/fonts/avenir-black-oblique/2ba9f875-66d9-414d-a426-5a012b443475.ttf") format("truetype"), url("static.ginetta.net/fonts/avenir-black-oblique/0a88351a-e628-4b1f-99eb-3a729518af0a.svg") format("svg"); }

@font-face {
  font-family: Avenir;
  font-weight: 800;
  font-style: normal;
  src: url("//static.ginetta.net/fonts/avenir-heavy/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot");
  src: local("Avenir Heavy"), url("//static.ginetta.net/fonts/avenir-heavy/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff") format("woff"), url("//static.ginetta.net/fonts/avenir-heavy/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf") format("truetype"), url("//static.ginetta.net/fonts/avenir-heavy/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg") format("svg"); }

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0; }

strong,
.t-bold {
  font-weight: 800; }

.t-centered {
  text-align: center; }

.t-smooth {
  -webkit-font-smoothing: antialiased; }

.t-sans {
  font-family: Avenir, Arial, sans-serif; }

/**
 * Color variables
 */
/**
 * Color functions
 */
/**
 * Color classes
 * Try to use only in the styleguide
 */
.c-ginetta-pink {
  background-color: #fb9fb1; }

.c-ginetta-pink--fill {
  fill: #fb9fb1; }

.c-ginetta-pink--fill-dark {
  fill: #f96e89; }

.c-ginetta-pink--color {
  color: #fb9fb1; }

.c-ginetta-salmon {
  background-color: #f0a88b; }

.c-ginetta-salmon--fill {
  fill: #f0a88b; }

.c-ginetta-salmon--fill-dark {
  fill: #ea865e; }

.c-ginetta-salmon--color {
  color: #f0a88b; }

.c-ginetta-orange {
  background-color: #e6ad7a; }

.c-ginetta-orange--fill {
  fill: #e6ad7a; }

.c-ginetta-orange--fill-dark {
  fill: #de934f; }

.c-ginetta-orange--color {
  color: #e6ad7a; }

.c-ginetta-green-olive {
  background-color: #a4c46a; }

.c-ginetta-green-olive--fill {
  fill: #a4c46a; }

.c-ginetta-green-olive--fill-dark {
  fill: #8db447; }

.c-ginetta-green-olive--color {
  color: #a4c46a; }

.c-ginetta-green {
  background-color: #73cb88; }

.c-ginetta-green--fill {
  fill: #73cb88; }

.c-ginetta-green--fill-dark {
  fill: #4ebd68; }

.c-ginetta-green--color {
  color: #73cb88; }

.c-ginetta-green-lake {
  background-color: #55ce9b; }

.c-ginetta-green-lake--fill {
  fill: #55ce9b; }

.c-ginetta-green-lake--fill-dark {
  fill: #36ba82; }

.c-ginetta-green-lake--color {
  color: #55ce9b; }

.c-ginetta-teal {
  background-color: #34cfad; }

.c-ginetta-teal--fill {
  fill: #34cfad; }

.c-ginetta-teal--fill-dark {
  fill: #28a88c; }

.c-ginetta-teal--color {
  color: #34cfad; }

.c-ginetta-cyan {
  background-color: #0fcdd0; }

.c-ginetta-cyan--fill {
  fill: #0fcdd0; }

.c-ginetta-cyan--fill-dark {
  fill: #0c9ea0; }

.c-ginetta-cyan--color {
  color: #0fcdd0; }

.c-ginetta-blue {
  background-color: #5ec5eb; }

.c-ginetta-blue--fill {
  fill: #5ec5eb; }

.c-ginetta-blue--fill-dark {
  fill: #31b5e5; }

.c-ginetta-blue--color {
  color: #5ec5eb; }

.c-ginetta-blue-sky {
  background-color: #80bff4; }

.c-ginetta-blue-sky--fill {
  fill: #80bff4; }

.c-ginetta-blue-sky--fill-dark {
  fill: #51a7f0; }

.c-ginetta-blue-sky--color {
  color: #80bff4; }

.c-ginetta-purple {
  background-color: #9eb8f8; }

.c-ginetta-purple--fill {
  fill: #9eb8f8; }

.c-ginetta-purple--fill-dark {
  fill: #6e95f5; }

.c-ginetta-purple--color {
  color: #9eb8f8; }

.c-ginetta-violet {
  background-color: #d0a9f6; }

.c-ginetta-violet--fill {
  fill: #d0a9f6; }

.c-ginetta-violet--fill-dark {
  fill: #b77bf1; }

.c-ginetta-violet--color {
  color: #d0a9f6; }

.c-client-aroov {
  background-color: #32b5dc;
  color: rgba(255, 255, 255, 0.95); }

.c-client-aroov--fill {
  fill: #32b5dc; }

.c-client-aroov--fill-dark {
  fill: #2097bb; }

.c-client-aroov--color {
  color: #32b5dc; }

.c-client-aroov-text {
  background-color: #4da5ca;
  color: rgba(255, 255, 255, 0.95); }

.c-client-aroov-text--fill {
  fill: #4da5ca; }

.c-client-aroov-text--fill-dark {
  fill: #348bb0; }

.c-client-aroov-text--color {
  color: #4da5ca; }

.c-client-avaloq {
  background-color: #003076;
  color: rgba(255, 255, 255, 0.95); }

.c-client-avaloq--fill {
  fill: #003076; }

.c-client-avaloq--fill-dark {
  fill: #001b43; }

.c-client-avaloq--color {
  color: #003076; }

.c-client-basler-hofmann {
  background-color: #dc2856;
  color: rgba(255, 255, 255, 0.95); }

.c-client-basler-hofmann--fill {
  fill: #dc2856; }

.c-client-basler-hofmann--fill-dark {
  fill: #b41d44; }

.c-client-basler-hofmann--color {
  color: #dc2856; }

.c-client-bh-black {
  background-color: #2f2f2f;
  color: rgba(255, 255, 255, 0.95); }

.c-client-bh-black--fill {
  fill: #2f2f2f; }

.c-client-bh-black--fill-dark {
  fill: #161616; }

.c-client-bh-black--color {
  color: #2f2f2f; }

.c-client-comet {
  background-color: #48646d;
  color: rgba(255, 255, 255, 0.95); }

.c-client-comet--fill {
  fill: #48646d; }

.c-client-comet--fill-dark {
  fill: #34484e; }

.c-client-comet--color {
  color: #48646d; }

.c-client-crowdhouse {
  background-color: #5fb6cb;
  color: rgba(255, 255, 255, 0.95); }

.c-client-crowdhouse--fill {
  fill: #5fb6cb; }

.c-client-crowdhouse--fill-dark {
  fill: #3da2ba; }

.c-client-crowdhouse--color {
  color: #5fb6cb; }

.c-client-crowdhouse-dark {
  background-color: #008092;
  color: rgba(255, 255, 255, 0.95); }

.c-client-crowdhouse-dark--fill {
  fill: #008092; }

.c-client-crowdhouse-dark--fill-dark {
  fill: #00535f; }

.c-client-crowdhouse-dark--color {
  color: #008092; }

.c-client-crowdhouse-light {
  background-color: #ebf2f2;
  color: rgba(255, 255, 255, 0.95); }

.c-client-crowdhouse-light--fill {
  fill: #ebf2f2; }

.c-client-crowdhouse-light--fill-dark {
  fill: #ccdede; }

.c-client-crowdhouse-light--color {
  color: #ebf2f2; }

.c-client-docsafe {
  background-color: #1781e3;
  color: rgba(255, 255, 255, 0.95); }

.c-client-docsafe--fill {
  fill: #1781e3; }

.c-client-docsafe--fill-dark {
  fill: #1267b5; }

.c-client-docsafe--color {
  color: #1781e3; }

.c-client-docsafe-grey {
  background-color: #2c2723;
  color: rgba(255, 255, 255, 0.95); }

.c-client-docsafe-grey--fill {
  fill: #2c2723; }

.c-client-docsafe-grey--fill-dark {
  fill: #100e0c; }

.c-client-docsafe-grey--color {
  color: #2c2723; }

.c-client-doodle {
  background-color: #0060df;
  color: rgba(255, 255, 255, 0.95); }

.c-client-doodle--fill {
  fill: #0060df; }

.c-client-doodle--fill-dark {
  fill: #004aac; }

.c-client-doodle--color {
  color: #0060df; }

.c-client-fantoche {
  background-color: #5eaeaa;
  color: rgba(255, 255, 255, 0.95); }

.c-client-fantoche--fill {
  fill: #5eaeaa; }

.c-client-fantoche--fill-dark {
  fill: #49908d; }

.c-client-fantoche--color {
  color: #5eaeaa; }

.c-client-filmcoopi {
  background-color: #fff507;
  color: rgba(255, 255, 255, 0.95); }

.c-client-filmcoopi--fill {
  fill: #fff507; }

.c-client-filmcoopi--fill-dark {
  fill: #d3ca00; }

.c-client-filmcoopi--color {
  color: #fff507; }

.c-client-helsana {
  background-color: #9c0240;
  color: rgba(255, 255, 255, 0.95); }

.c-client-helsana--fill {
  fill: #9c0240; }

.c-client-helsana--fill-dark {
  fill: #6a012b; }

.c-client-helsana--color {
  color: #9c0240; }

.c-client-helsana-gold {
  background-color: #ddc48c;
  color: rgba(255, 255, 255, 0.95); }

.c-client-helsana-gold--fill {
  fill: #ddc48c; }

.c-client-helsana-gold--fill-dark {
  fill: #d1b065; }

.c-client-helsana-gold--color {
  color: #ddc48c; }

.c-client-endress {
  background-color: #007caa;
  color: rgba(255, 255, 255, 0.95); }

.c-client-endress--fill {
  fill: #007caa; }

.c-client-endress--fill-dark {
  fill: #005777; }

.c-client-endress--color {
  color: #007caa; }

.c-client-endress-magenta {
  background-color: #a8005c;
  color: rgba(255, 255, 255, 0.95); }

.c-client-endress-magenta--fill {
  fill: #a8005c; }

.c-client-endress-magenta--fill-dark {
  fill: #750040; }

.c-client-endress-magenta--color {
  color: #a8005c; }

.c-client-hilti {
  background-color: #d0021b;
  color: rgba(255, 255, 255, 0.95); }

.c-client-hilti--fill {
  fill: #d0021b; }

.c-client-hilti--fill-dark {
  fill: #9d0214; }

.c-client-hilti--color {
  color: #d0021b; }

.c-client-hilti-website {
  background-color: #d2071e;
  color: rgba(255, 255, 255, 0.95); }

.c-client-hilti-website--fill {
  fill: #d2071e; }

.c-client-hilti-website--fill-dark {
  fill: #a10517; }

.c-client-hilti-website--color {
  color: #d2071e; }

.c-client-lizzy {
  background-color: #e9eaf0;
  color: rgba(255, 255, 255, 0.95); }

.c-client-lizzy--fill {
  fill: #e9eaf0; }

.c-client-lizzy--fill-dark {
  fill: #cbcddb; }

.c-client-lizzy--color {
  color: #e9eaf0; }

.c-client-lizzy-purple {
  background-color: #00074e;
  color: rgba(255, 255, 255, 0.95); }

.c-client-lizzy-purple--fill {
  fill: #00074e; }

.c-client-lizzy-purple--fill-dark {
  fill: #00021b; }

.c-client-lizzy-purple--color {
  color: #00074e; }

.c-client-lizzy-pink {
  background-color: #e61b58;
  color: rgba(255, 255, 255, 0.95); }

.c-client-lizzy-pink--fill {
  fill: #e61b58; }

.c-client-lizzy-pink--fill-dark {
  fill: #ba1446; }

.c-client-lizzy-pink--color {
  color: #e61b58; }

.c-client-lizzy-darkgrey {
  background-color: #8f909b;
  color: rgba(255, 255, 255, 0.95); }

.c-client-lizzy-darkgrey--fill {
  fill: #8f909b; }

.c-client-lizzy-darkgrey--fill-dark {
  fill: #757682; }

.c-client-lizzy-darkgrey--color {
  color: #8f909b; }

.c-client-lizzy-blue {
  background-color: #006ce2;
  color: rgba(255, 255, 255, 0.95); }

.c-client-lizzy-blue--fill {
  fill: #006ce2; }

.c-client-lizzy-blue--fill-dark {
  fill: #0054af; }

.c-client-lizzy-blue--color {
  color: #006ce2; }

.c-client-local {
  background-color: #0d9bdb;
  color: rgba(255, 255, 255, 0.95); }

.c-client-local--fill {
  fill: #0d9bdb; }

.c-client-local--fill-dark {
  fill: #0a79ab; }

.c-client-local--color {
  color: #0d9bdb; }

.c-client-migros {
  background-color: #ff6600;
  color: rgba(255, 255, 255, 0.95); }

.c-client-migros--fill {
  fill: #ff6600; }

.c-client-migros--fill-dark {
  fill: #cc5200; }

.c-client-migros--color {
  color: #ff6600; }

.c-client-mobility {
  background-color: #c31622;
  color: rgba(255, 255, 255, 0.95); }

.c-client-mobility--fill {
  fill: #c31622; }

.c-client-mobility--fill-dark {
  fill: #95111a; }

.c-client-mobility--color {
  color: #c31622; }

.c-client-puma {
  background-color: #00853f;
  color: rgba(255, 255, 255, 0.95); }

.c-client-puma--fill {
  fill: #00853f; }

.c-client-puma--fill-dark {
  fill: #005227; }

.c-client-puma--color {
  color: #00853f; }

.c-client-puma-green {
  background-color: #006e34;
  color: rgba(255, 255, 255, 0.95); }

.c-client-puma-green--fill {
  fill: #006e34; }

.c-client-puma-green--fill-dark {
  fill: #003b1c; }

.c-client-puma-green--color {
  color: #006e34; }

.c-client-nzz {
  background-color: #3b8fc9;
  color: rgba(255, 255, 255, 0.95); }

.c-client-nzz--fill {
  fill: #3b8fc9; }

.c-client-nzz--fill-dark {
  fill: #2d73a4; }

.c-client-nzz--color {
  color: #3b8fc9; }

.c-client-paycall {
  background-color: #42414d;
  color: rgba(255, 255, 255, 0.95); }

.c-client-paycall--fill {
  fill: #42414d; }

.c-client-paycall--fill-dark {
  fill: #2a2a31; }

.c-client-paycall--color {
  color: #42414d; }

.c-client-paycall-violet {
  background-color: #4d4974;
  color: rgba(255, 255, 255, 0.95); }

.c-client-paycall-violet--fill {
  fill: #4d4974; }

.c-client-paycall-violet--fill-dark {
  fill: #383555; }

.c-client-paycall-violet--color {
  color: #4d4974; }

.c-client-postfinance {
  background-color: #f8cf34;
  color: rgba(255, 255, 255, 0.95); }

.c-client-postfinance--fill {
  fill: #f8cf34; }

.c-client-postfinance--fill-dark {
  fill: #f1c008; }

.c-client-postfinance--color {
  color: #f8cf34; }

.c-client-postfinanceapp {
  background-color: #00545E;
  color: rgba(255, 255, 255, 0.95); }

.c-client-postfinanceapp--fill {
  fill: #00545E; }

.c-client-postfinanceapp--fill-dark {
  fill: #00262b; }

.c-client-postfinanceapp--color {
  color: #00545E; }

.c-client-raiffeisen {
  background-color: #c84a48;
  color: rgba(255, 255, 255, 0.95); }

.c-client-raiffeisen--fill {
  fill: #c84a48; }

.c-client-raiffeisen--fill-dark {
  fill: #aa3533; }

.c-client-raiffeisen--color {
  color: #c84a48; }

.c-client-sbb {
  background-color: #f10000;
  color: rgba(255, 255, 255, 0.95); }

.c-client-sbb--fill {
  fill: #f10000; }

.c-client-sbb--fill-dark {
  fill: #be0000; }

.c-client-sbb--color {
  color: #f10000; }

.c-client-siroop {
  background-color: #41cadf;
  color: rgba(255, 255, 255, 0.95); }

.c-client-siroop--fill {
  fill: #41cadf; }

.c-client-siroop--fill-dark {
  fill: #22b4cb; }

.c-client-siroop--color {
  color: #41cadf; }

.c-client-siroop-grey {
  background-color: #f1f5f7;
  color: rgba(255, 255, 255, 0.95); }

.c-client-siroop-grey--fill {
  fill: #f1f5f7; }

.c-client-siroop-grey--fill-dark {
  fill: #d1dee4; }

.c-client-siroop-grey--color {
  color: #f1f5f7; }

.c-client-swisscom {
  background-color: #000d57;
  color: rgba(255, 255, 255, 0.95); }

.c-client-swisscom--fill {
  fill: #000d57; }

.c-client-swisscom--fill-dark {
  fill: #000524; }

.c-client-swisscom--color {
  color: #000d57; }

.c-client-sympany {
  background-color: #ca0018;
  color: rgba(255, 255, 255, 0.95); }

.c-client-sympany--fill {
  fill: #ca0018; }

.c-client-sympany--fill-dark {
  fill: #970012; }

.c-client-sympany--color {
  color: #ca0018; }

.c-client-sympany-light {
  background-color: #fdf7f8;
  color: rgba(255, 255, 255, 0.95); }

.c-client-sympany-light--fill {
  fill: #fdf7f8; }

.c-client-sympany-light--fill-dark {
  fill: #f3ced4; }

.c-client-sympany-light--color {
  color: #fdf7f8; }

.c-client-uepaa {
  background-color: #0e8bcf;
  color: rgba(255, 255, 255, 0.95); }

.c-client-uepaa--fill {
  fill: #0e8bcf; }

.c-client-uepaa--fill-dark {
  fill: #0b6b9f; }

.c-client-uepaa--color {
  color: #0e8bcf; }

.c-client-testingtime {
  background-color: #84c35a;
  color: rgba(255, 255, 255, 0.95); }

.c-client-testingtime--fill {
  fill: #84c35a; }

.c-client-testingtime--fill-dark {
  fill: #6aac3e; }

.c-client-testingtime--color {
  color: #84c35a; }

.c-client-twint-dark {
  background-color: #1d262c;
  color: rgba(255, 255, 255, 0.95); }

.c-client-twint-dark--fill {
  fill: #1d262c; }

.c-client-twint-dark--fill-dark {
  fill: #090b0d; }

.c-client-twint-dark--color {
  color: #1d262c; }

.c-client-twint-grey {
  background-color: #f3f3f3;
  color: rgba(255, 255, 255, 0.95); }

.c-client-twint-grey--fill {
  fill: #f3f3f3; }

.c-client-twint-grey--fill-dark {
  fill: #dadada; }

.c-client-twint-grey--color {
  color: #f3f3f3; }

.c-client-twint-blue {
  background-color: #50a4d3;
  color: rgba(255, 255, 255, 0.95); }

.c-client-twint-blue--fill {
  fill: #50a4d3; }

.c-client-twint-blue--fill-dark {
  fill: #308cc0; }

.c-client-twint-blue--color {
  color: #50a4d3; }

.c-client-zurich {
  background-color: #0c2e96;
  color: rgba(255, 255, 255, 0.95); }

.c-client-zurich--fill {
  fill: #0c2e96; }

.c-client-zurich--fill-dark {
  fill: #082067; }

.c-client-zurich--color {
  color: #0c2e96; }

.c-client-bfu {
  background-color: #00b2aa;
  color: rgba(255, 255, 255, 0.95); }

.c-client-bfu--fill {
  fill: #00b2aa; }

.c-client-bfu--fill-dark {
  fill: #007f79; }

.c-client-bfu--color {
  color: #00b2aa; }

.c-client-jutzler {
  background-color: #2f80ed;
  color: rgba(255, 255, 255, 0.95); }

.c-client-jutzler--fill {
  fill: #2f80ed; }

.c-client-jutzler--fill-dark {
  fill: #1366d6; }

.c-client-jutzler--color {
  color: #2f80ed; }

.c-client-vontobel {
  background-color: #ffd500;
  color: rgba(255, 255, 255, 0.95); }

.c-client-vontobel--fill {
  fill: #ffd500; }

.c-client-vontobel--fill-dark {
  fill: #ccaa00; }

.c-client-vontobel--color {
  color: #ffd500; }

.c-ui-link {
  background-color: cornflowerblue; }

.c-ui-link--fill {
  fill: cornflowerblue; }

.c-ui-link--fill-dark {
  fill: #3676e8; }

.c-ui-link--color {
  color: cornflowerblue; }

.c-neutral-black {
  background-color: #16161d; }

.c-neutral-black--fill {
  fill: #16161d; }

.c-neutral-black--fill-dark {
  fill: black; }

.c-neutral-black--color {
  color: #16161d; }

.c-neutral-graphite {
  background-color: #666; }

.c-neutral-graphite--fill {
  fill: #666; }

.c-neutral-graphite--fill-dark {
  fill: #4d4d4d; }

.c-neutral-graphite--color {
  color: #666; }

.c-neutral-gray {
  background-color: #ddd; }

.c-neutral-gray--fill {
  fill: #ddd; }

.c-neutral-gray--fill-dark {
  fill: #c4c4c4; }

.c-neutral-gray--color {
  color: #ddd; }

.c-neutral-silver {
  background-color: #fafafa; }

.c-neutral-silver--fill {
  fill: #fafafa; }

.c-neutral-silver--fill-dark {
  fill: #e1e1e1; }

.c-neutral-silver--color {
  color: #fafafa; }

.c-neutral-white {
  background-color: #fff; }

.c-neutral-white--fill {
  fill: #fff; }

.c-neutral-white--fill-dark {
  fill: #e6e6e6; }

.c-neutral-white--color {
  color: #fff; }

/*
   Hack to overcome the lack of color contrast check
 */
.c-client-siroop-grey {
  color: rgba(0, 0, 0, 0.8); }

.c-client-filmcoopi {
  color: black; }

.color-aroov,
.c-color-aroov--color blockquote {
  color: #32b5dc; }

.l-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px; }

.l-container--medium {
  max-width: 800px; }

.l-container--small {
  max-width: 680px; }

.l-container--spaceless {
  padding-left: 0;
  padding-right: 0; }

.l-container--left-aligned {
  margin-left: 0; }

.js-breakpoint:after,
.js-breakpoint {
  content: '{"mobile":"only screen and (max-width:740px)","tablet":"only screen and (max-width:1050px)","desktop":"only screen and (min-width:1051px)","print":"print"}';
  font-family: '{"mobile":"only screen and (max-width:740px)","tablet":"only screen and (max-width:1050px)","desktop":"only screen and (min-width:1051px)","print":"print"}'; }

.l-grid-hipster__row {
  display: flex;
  flex-flow: row wrap; }
  .l-grid-hipster__row--vertical {
    flex-direction: column; }

.l-grid-hipster__cell {
  flex: 1;
  background: rgba(255, 0, 0, 0.1); }

@media only screen and (max-width: 740px) {
  .l-grid-hipster__row {
    display: block; } }

.icon {
  width: 24px;
  height: 24px;
  fill: currentColor; }

.narrative img,
.narrative video {
  max-width: 100%; }

.narrative h1:not([class]) {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.8rem;
  line-height: 1.14; }
  @media only screen and (max-width: 740px) {
    .narrative h1:not([class]) {
      font-size: 2rem; } }

.narrative h2:not([class]) {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2rem;
  line-height: 1.3; }
  @media only screen and (max-width: 740px) {
    .narrative h2:not([class]) {
      font-size: 1.5rem; } }

.narrative h3:not([class]) {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.3; }

.narrative h4:not([class]) {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.1rem;
  line-height: 1.3; }

.narrative h5:not([class]),
.narrative h6:not([class]) {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.65; }
  @media only screen and (max-width: 740px) {
    .narrative h5:not([class]),
    .narrative h6:not([class]) {
      font-size: 1rem; } }

.narrative p:not([class]),
.narrative li {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.65; }
  @media only screen and (max-width: 740px) {
    .narrative p:not([class]),
    .narrative li {
      font-size: 0.9rem; } }

.narrative figcaption:not([class]) {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.4;
  color: #666; }

.narrative table {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.65; }
  @media only screen and (max-width: 740px) {
    .narrative table {
      font-size: 0.9rem; } }

.narrative a {
  color: cornflowerblue;
  text-decoration: none; }
  .narrative a:hover, .narrative a:focus {
    text-decoration: underline; }

.narrative figure {
  margin: 0; }

.narrative figure + figure {
  margin-top: 3rem; }

.narrative h1,
.narrative h2,
.narrative h3,
.narrative h4,
.narrative h5,
.narrative h6 {
  margin-top: 3rem; }

.narrative small {
  font-size: 90%; }

.narrative img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.narrative figure,
.narrative table,
.narrative figcaption,
.narrative ul,
.narrative ol,
.narrative p {
  margin-top: 1em; }

.narrative li {
  margin-top: .5em; }

.narrative img,
.narrative video {
  margin-top: 2rem;
  margin-bottom: 0; }

.narrative p + p {
  margin-top: 1.5em; }

.narrative h2 + p {
  margin-top: 2em; }

.narrative figcaption {
  margin-bottom: 2em; }

.narrative td {
  padding-top: 1.6em; }

.narrative > *:first-child {
  margin-top: 0; }

.narrative td {
  vertical-align: top; }

.narrative .timeline {
  margin-top: 4rem; }

.narrative .no-vr {
  margin-top: 0; }

.ratio {
  display: block;
  position: relative; }
  .ratio--panorama {
    padding-top: 50%; }
  .ratio--1to4 {
    padding-top: 25%; }
  .ratio--square {
    padding-top: 100%; }
    @media only screen and (max-width: 740px) {
      .ratio--square {
        padding-top: 50%; } }
  .ratio--3to2 {
    padding-top: 66.66667%; }
  .ratio__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

/**
 * Sizes function
 * Expects a size name (default: medium) and returns it's value in rem
 */
/* Helpers for the styleguide to display the sizes */
.s-huge {
  width: 136px;
  height: 136px; }

.s-inner-huge {
  padding: 136px; }

.s-vertical-huge {
  padding-top: 136px;
  padding-bottom: 136px; }

.s-x-large {
  width: 64px;
  height: 64px; }

.s-inner-x-large {
  padding: 64px; }

.s-vertical-x-large {
  padding-top: 64px;
  padding-bottom: 64px; }

.s-large {
  width: 32px;
  height: 32px; }

.s-inner-large {
  padding: 32px; }

.s-vertical-large {
  padding-top: 32px;
  padding-bottom: 32px; }

.s-medium {
  width: 24px;
  height: 24px; }

.s-inner-medium {
  padding: 24px; }

.s-vertical-medium {
  padding-top: 24px;
  padding-bottom: 24px; }

.s-normal {
  width: 16px;
  height: 16px; }

.s-inner-normal {
  padding: 16px; }

.s-vertical-normal {
  padding-top: 16px;
  padding-bottom: 16px; }

.s-small {
  width: 12px;
  height: 12px; }

.s-inner-small {
  padding: 12px; }

.s-vertical-small {
  padding-top: 12px;
  padding-bottom: 12px; }

.s-tiny {
  width: 8px;
  height: 8px; }

.s-inner-tiny {
  padding: 8px; }

.s-vertical-tiny {
  padding-top: 8px;
  padding-bottom: 8px; }

.d-b {
  display: block; }

.affix-header {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  opacity: 1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .affix-header__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    height: 60px;
    overflow: hidden; }
    @media only screen and (max-width: 740px) {
      .affix-header__container {
        height: 60px; } }
  .affix-header__go-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .affix-header__arrows {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    display: flex;
    align-items: center; }
  .affix-header__go-prev {
    transform: rotate(180deg) !important;
    margin-right: 24px; }
  .affix-header__logo {
    display: block;
    width: auto !important;
    height: 32px !important;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform 100ms ease-in-out; }
    .affix-header__logo svg {
      color: inherit;
      width: 100%; }
    .affix-header__logo path {
      fill: currentColor; }
  .affix-header__logo-container, .affix-header__title-container {
    transition: transform .2s ease-in-out, opacity .3s ease-in-out; }
  .affix-header__logo-container {
    position: relative;
    transform: translate(0, 0); }
  .affix-header__title-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    opacity: 0; }
  .affix-header--scrolled .affix-header__logo-container {
    transform: translate(0, -100%);
    opacity: 0; }
  .affix-header--scrolled .affix-header__title-container {
    transform: translate(-50%, -50%);
    opacity: 1; }
  .affix-header__link {
    display: inline-block;
    color: inherit;
    width: 100%; }

/**
 * Sizes function
 * Expects a size name (default: medium) and returns it's value in rem
 */
/* Helper for avatar-sizes */
.avatar--small {
  width: 120px;
  height: 120px; }

.avatar--medium {
  width: 160px;
  height: 160px; }

.avatar--large {
  width: 190px;
  height: 190px; }

.avatar {
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  text-align: center; }
  .avatar__image {
    position: absolute;
    width: auto;
    transform: translateX(-50%);
    width: auto;
    max-height: 100%;
    border-radius: 50%;
    background: #fafafa; }

.button {
  display: inline-block;
  padding: 10px 25px;
  background: #16161d;
  border: none;
  outline: none;
  user-select: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color .15s ease-in-out; }
  .button:hover {
    background-color: #424257; }
  .button[disable] {
    color: #cccccc;
    background-color: #424257;
    pointer-events: none; }

.callout {
  text-align: center;
  margin: 0;
  background-size: cover; }
  .callout:after {
    content: close-quote; }
  .callout:before {
    content: open-quote; }

.card {
  display: block;
  position: relative;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-size: cover; }
  .card__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.0980392)); }
  .card__logo {
    max-width: 130px;
    display: none; }
  .card__divider {
    position: absolute;
    top: 50%;
    left: calc(50% - 48px / 2);
    width: 48px;
    margin-top: .65rem;
    border: 0;
    border-bottom: 1px solid white;
    opacity: 0;
    transform: scaleX(0);
    transition: transform .2s, opacity .2s; }
  .card__footer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px; }
  .card__title, .card__subtitle {
    display: block;
    text-align: center; }
  .card__title {
    color: #fff;
    font-weight: 800;
    transition: 200ms transform; }
  .card__subtitle {
    height: 22px;
    color: #fff;
    transition: 200ms opacity, 200ms transform;
    margin-top: 12px; }
  .card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: #16161d;
    opacity: .8;
    transform: translate3d(0, 0, 0);
    will-change: opacity;
    transition: 200ms opacity, 100ms background-color; }
  .card__content {
    z-index: 2; }
  .card:hover .card__title {
    transform: translateY(-10px); }
  .card:hover .card__subtitle {
    opacity: 1;
    transform: translateY(10px); }
  .card:hover .card__divider {
    opacity: 1;
    transform: scaleX(1); }
  .card:hover .card__overlay {
    opacity: .9; }

.carousel {
  position: relative; }

.carousel__button {
  position: absolute;
  top: 50%;
  width: 4rem;
  height: 4rem;
  border: 0;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  cursor: pointer;
  z-index: 2;
  outline: none;
  transition: box-shadow .1s linear, transform .1s;
  transform: translateY(-50%) scale(1); }
  .carousel__button:active {
    transform: translateY(-50%) scale(0.95); }
  .carousel__button:hover {
    opacity: 0.9; }
  .carousel__button--prev {
    left: 20px; }
    .carousel__button--prev:before {
      transition: transform 0.2s;
      transform: translate(3px, 0px) rotate(-135deg); }
  .carousel__button--next {
    right: 20px; }
    .carousel__button--next:before {
      transform: translate(-3px, 0px) rotate(45deg); }
  .carousel__button:before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-right: 0.05rem solid #666;
    border-top: 0.05rem solid #666; }
  .carousel__button:hover, .carousel__button:focus {
    background: #fff;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6); }
  @media only screen and (max-width: 740px) {
    .carousel__button {
      display: none; } }

.carousel__item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  opacity: .2;
  transition: opacity .2s ease-in-out;
  outline: none;
  width: 1200px; }
  .carousel__item.slick-current, .carousel__item:focus {
    opacity: 1; }
  @media only screen and (max-width: 740px) {
    .carousel__item {
      padding-left: 0;
      padding-right: 0; } }

.carousel__image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 1rem; }

.carousel__dots {
  height: 30px;
  padding: 16px 90px;
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: center; }
  .carousel__dots button {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    background: transparent;
    outline: none;
    padding: 0;
    cursor: pointer;
    border-radius: 50%;
    color: inherit;
    border: 1px solid currentColor;
    width: 10px;
    height: 10px; }
    .carousel__dots button:hover {
      background-color: currentColor; }
  .carousel__dots > li.slick-active button {
    background-color: currentColor; }
  .carousel__dots > li:not(:first-child) {
    margin-left: 8px; }

.footer {
  padding: 64px 0px 64px 0px;
  text-align: center; }
  .footer__logo {
    display: block;
    width: auto !important;
    height: 40px !important;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform 100ms ease-in-out; }
    .footer__logo g {
      fill: white; }
  .footer__info-block {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 740px) {
      .footer__info-block--links {
        flex-direction: column; } }
    .footer__info-block--icons {
      margin-top: 24px; }
    .footer__info-block--logo {
      margin-top: 32px; }
  .footer__link {
    display: inline-block;
    margin-bottom: 24px; }
    .footer__link .icon-link__text {
      color: #fff; }
    .footer__link svg {
      fill: #fff; }
  .footer__info-block--icons .footer__link:not(:last-child) {
    margin-right: 32px; }
  .footer__info-block--links .footer__link:not(:last-child) {
    margin-right: 32px; }
    @media only screen and (max-width: 740px) {
      .footer__info-block--links .footer__link:not(:last-child) {
        margin-right: 0; } }

.hr--short {
  width: 48px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid white;
  margin: 0 auto;
  display: inline-block; }

.header-minimal {
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;
  padding-top: 64px;
  position: relative;
  padding-top: 124px; }
  @media only screen and (max-width: 740px) {
    .header-minimal {
      padding-top: 124px;
      height: 80vh !important; } }
  .header-minimal__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; }
  .header-minimal__image {
    transform: translate3d(0, 0, 0); }
  .header-minimal__title {
    text-align: center;
    transform: translate3d(0, 0, 0);
    padding-bottom: 32px; }
    @media only screen and (max-width: 740px) {
      .header-minimal__title {
        transform: none !important;
        opacity: 1 !important; } }
  .header-minimal__image-abs {
    position: absolute;
    max-width: 75%;
    backface-visibility: hidden;
    will-change: transform;
    transform: translateZ(0); }
    @media only screen and (max-width: 740px) {
      .header-minimal__image-abs:not(.header-minimal__image-abs--manual-space-mobile) {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        top: auto !important;
        bottom: 0 !important;
        left: 50% !important;
        right: auto !important;
        transform-origin: center center;
        transform: translateX(-50%) !important; }
        .header-minimal__image-abs:not(.header-minimal__image-abs--manual-space-mobile).header-minimal__image-abs--space-on-mobile {
          top: 50% !important;
          bottom: auto !important;
          transform: translate(-50%, -50%) !important; } }
    @media only screen and (max-width: 740px) {
      .header-minimal__image-abs--manual-space-mobile {
        max-width: 100%; } }
    .header-minimal__image-abs.is-centered {
      left: 50%;
      transform: translateX(-50%); }
  .header-minimal__images {
    position: relative;
    flex-grow: 1; }
    @media only screen and (max-width: 740px) {
      .header-minimal__images {
        margin-left: -16px;
        margin-right: -16px; } }
  .header-minimal__image {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-shrink: 0; }

.header-minimal--image-right {
  flex-direction: row; }

.header-minimal--centered-image .header-minimal__image {
  align-self: center;
  padding-bottom: 2rem; }

.header-minimal--has-abs-images {
  overflow: hidden; }

.hotspot {
  margin-top: 32px;
  display: flex;
  justify-content: center; }

.hotspot__screenshot {
  position: relative;
  display: block; }

.hotspot__image {
  max-width: 100%;
  margin: 0; }

.hotspot__item {
  position: absolute; }

.hotspot__item-dot {
  display: block;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.6); }

.hotspot__item-popup {
  opacity: 0;
  text-align: left;
  width: 15rem;
  padding: 0.5rem;
  z-index: -1; }

.hotspot__item-arrow {
  display: inline-block;
  position: absolute; }
  .hotspot__item-arrow:after {
    content: '';
    display: block;
    position: absolute;
    border-top: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid transparent; }

.hotspot__item-arrow-left:after {
  right: 100%;
  border-right-color: currentColor; }

.hotspot__item-arrow-right:after {
  left: 100%;
  border-left-color: currentColor; }

.hotspot__item-arrow-up:after {
  bottom: 100%;
  border-bottom-color: currentColor; }

.hotspot__item-arrow-down:after {
  top: 100%;
  border-top-color: currentColor; }

.hotspot__item-arrow-left-corner:after {
  left: 0.3rem; }

.hotspot__item-arrow-right-corner:after {
  right: 0.3rem; }

.hotspot__item-arrow-top-corner:after {
  top: 0.3rem; }

.hotspot__item-arrow-bottom-corner:after {
  bottom: 0.3rem; }

.hotspot__item-radio {
  visibility: hidden; }

.hotspot__item-radio:checked ~ .hotspot__item-popup {
  opacity: 1;
  z-index: 2; }

/**
 * Define the basic popup translations based on
 * the arrow direction and arrow corner position
 */
.hotspot__item-arrow-up.hotspot__item-arrow-left-corner {
  transform: translate(-0.95rem, 2.5rem); }

.hotspot__item-arrow-up.hotspot__item-arrow-right-corner {
  transform: translate(-16.25rem, 2.5rem); }

.hotspot__item-arrow-right.hotspot__item-arrow-top-corner {
  transform: translate(-18rem, -0.3rem); }

.hotspot__item-arrow-left.hotspot__item-arrow-top-corner {
  transform: translate(0.75rem, -0.3rem); }

.hotspot__item-arrow-left.hotspot__item-arrow-bottom-corner {
  transform: translate(0.75rem, -0.5rem); }

.hotspot__item-arrow-right.hotspot__item-arrow-bottom-corner {
  transform: translate(-18rem, -0.5rem); }

.hotspot__item-arrow-down.hotspot__item-arrow-left-corner {
  transform: translate(-0.95rem, -1.75rem); }

.hotspot__item-arrow-down.hotspot__item-arrow-right-corner {
  transform: translate(-16.25rem, -1.75rem); }

.icon-link {
  text-decoration: none;
  opacity: 1;
  transition: 0.4s opacity;
  will-change: opacity;
  transform: translate3d(0, 0, 0);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-link:hover {
    opacity: .6; }
  .icon-link__icon {
    width: 24px;
    height: 24px; }
  .icon-link__text {
    margin-left: 12px; }
  @media only screen and (max-width: 740px) {
    .icon-link--hide-text-on-mobile .icon-link__text {
      display: none; } }
  .icon-link--rtl {
    flex-direction: row-reverse; }
  .icon-link--rtl .icon-link__text {
    margin-left: 0;
    margin-right: 12px; }
  .icon-link--text-only .icon-link__text {
    margin-left: 0;
    margin-right: 0; }

.image-link {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  backface-visibility: hidden; }
  .image-link__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 24px;
    z-index: 2; }
  .image-link__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .3;
    background-color: currentColor;
    transition: 0.2s opacity, transform 0.3s ease-in-out;
    will-change: opacity, transform;
    transform: translate3d(0, 0, 0) scaleY(1);
    transform-origin: center center; }
  .image-link__title {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 34px, 0);
    will-change: transform, transform;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; }
  .image-link__subtitle {
    margin-top: 8px;
    opacity: 0;
    transform: translate3d(0, 34px, 0);
    will-change: opacity, transform;
    transition: opacity 0.2s, transform 0.35s cubic-bezier(0.13, 1, 0.4, 1);
    line-height: 34px; }
  .image-link:hover .image-link__overlay {
    opacity: 0;
    transform: translate3d(0, -34px, 0) scaleY(0);
    transition: opacity .3s, transform 0.5s ease-in-out; }
  .image-link:hover .image-link__title,
  .image-link:hover .image-link__subtitle {
    transform: translate3d(0, 0, 0); }
  .image-link:hover .image-link__title {
    transition: transform 0.35s; }
  .image-link:hover .image-link__subtitle {
    opacity: 1;
    transition-delay: 0.15s;
    transition-duration: 0.35s; }

.banner {
  background-color: #ffd700;
  color: #000;
  padding: 24px;
  text-align: center;
  font-size: 12px;
  font-weight: bold; }
  .banner a {
    color: #000;
    text-decoration: underline; }
  .banner .t-lead {
    font-family: Avenir, Arial, sans-serif; }

.intro__header {
  display: flex;
  align-items: center; }

.intro__logo {
  width: 64px;
  margin-right: 24px; }

.intro__title {
  flex: 1; }

.intro__body {
  margin-top: 12px;
  opacity: 0.8; }

.list-pageable {
  text-align: center; }
  .list-pageable:not(.is-expanded) .list-pageable__hidden-projects {
    display: none; }
  .list-pageable__show-more {
    margin: 80px 0px;
    width: 40%;
    margin-right: auto;
    margin-left: auto; }
    .list-pageable__show-more.is-hidden {
      display: none; }

.list {
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0; }

.list__item {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.65;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px; }
  .list__item:first-child {
    padding-top: 0; }
  .list__item:last-child {
    padding-bottom: 0; }
  .list__item:not(:last-child) {
    position: relative; }
    .list__item:not(:last-child)::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: currentColor;
      opacity: .2;
      content: ''; }

.list__item-icon {
  display: none; }
  .list__item-icon svg {
    display: inline-block;
    vertical-align: middle;
    width: 1em; }

.list--dark .list__item-icon svg {
  fill: #4d4974;
  stroke: #4d4974; }

.list--tick {
  stroke: currentColor; }
  .list--tick .list__item-icon {
    display: inline-block; }

.list--number {
  counter-reset: number-counter; }
  .list--number .list__item {
    counter-increment: number-counter; }
  .list--number .list__item:before {
    content: counter(number-counter) ".";
    vertical-align: top; }

.list--tick .list__item-content, .list--number .list__item-content {
  padding-left: 16px; }

.list--tick .list__item::after, .list--number .list__item::after {
  display: none; }

.project-header {
  position: relative;
  margin: auto;
  text-align: center; }

.project-header__highlight {
  position: relative;
  margin-bottom: 3.5em; }

.project-header__background {
  position: absolute;
  height: 85%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.project-header__device {
  position: relative;
  margin: auto auto 0 auto;
  padding-top: 10%; }

.project-header__device.iphone-6s {
  width: 25%;
  min-width: 150px;
  max-width: 250px; }

.project-header__device.dell-hd {
  width: 70%;
  min-width: 150px;
  max-width: 840px; }

.project-header__device.macbook-air {
  width: 70%;
  min-width: 150px;
  max-width: 840px; }

.project-header__device.ipad-air2 {
  width: 70%;
  max-width: 840px;
  min-width: 150px; }

.project-header__separator {
  width: 100px;
  height: 2px;
  margin: 3.5em auto;
  background-color: #fafafa; }

.project-header__slogan {
  margin-bottom: 6em; }

/* Multiple box shadows to achieve the glass lens effect */
/* Lens */
/* Text while loading */
.screenshot-magnifying {
  text-align: center; }

html.magnifying > body {
  overflow-x: hidden; }

.magnify {
  position: relative;
  display: inline-block; }

.magnify-lens {
  position: absolute;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  cursor: none;
  display: none;
  z-index: 2; }

.magnify-lens.loading {
  background: #16161d !important;
  opacity: 0.75; }

.magnify-lens.loading:after {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  color: #fff;
  content: "Loading...";
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, 0.8); }

.section {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  @media only screen and (max-width: 740px) {
    .section {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .section__section-title {
    margin-bottom: 1rem; }
  .section__section-number {
    margin-right: 12px; }

.section-small {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.aroov-research {
  position: relative;
  padding-top: 30vh;
  padding-bottom: 30vh; }
  .aroov-research .l-container {
    position: relative;
    z-index: 1; }
  .aroov-research .narrative p {
    font-size: 1.2rem; }
  .aroov-research:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../content/images/aroov/research.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: .2; }

.team {
  position: relative;
  margin: auto;
  text-align: center; }

.team__members {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 64px 0 0;
  padding: 0; }

.team__item {
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 24px;
  min-width: 200px;
  max-width: 100%; }
  .team__item-name {
    margin-top: 12px; }

.team.c-color-aroov--color blockquote {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto; }

/* Avatar positioned half his height and taking into consideration section padding top */
.testimonial__avatar {
  position: relative;
  margin: -8.5rem auto 0;
  text-align: center; }

.testimonial__callout {
  margin-top: 2rem; }

.testimonial__author {
  margin-top: 24px;
  text-align: center; }

.testimonial__name {
  display: block; }

.timeline-horizontal {
  padding: 0; }

.timeline-horizontal__item {
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  outline: none; }
  .timeline-horizontal__item > *:not(:last-child) {
    margin-right: 25px; }

.timeline-horizontal__dots {
  position: relative;
  padding: 50px 0;
  overflow: hidden; }
  .timeline-horizontal__dots ul {
    padding: 0; }

.timeline-horizontal__dots-line {
  position: absolute;
  z-index: 0;
  content: "";
  height: 2px;
  width: 0;
  top: 50%;
  left: 0;
  background-color: #0d9bdb;
  transition: width .2s ease-in-out; }

.timeline-horizontal__dots::before {
  position: absolute;
  z-index: 0;
  content: "";
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  background-color: #b9e6fb; }

.timeline-horizontal__dots .slick-dots {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between; }
  .timeline-horizontal__dots .slick-dots * {
    outline: none; }
  .timeline-horizontal__dots .slick-dots li {
    display: inline;
    position: relative;
    height: 30px; }
    .timeline-horizontal__dots .slick-dots li button {
      color: transparent;
      border: none;
      position: relative;
      z-index: 1;
      background: white;
      border-radius: 100%;
      border-color: #0d9bdb;
      border-style: solid;
      border-width: 2px;
      height: 100%;
      width: 30px;
      cursor: pointer;
      transition: border-color .2s ease-in-out; }
      .timeline-horizontal__dots .slick-dots li button::after {
        position: absolute;
        content: "";
        background-color: transparent;
        border-radius: 100%;
        top: 4px;
        bottom: 4px;
        left: 4px;
        right: 4px;
        transition: background-color .2s ease-in-out; }
    .timeline-horizontal__dots .slick-dots li:hover button {
      border-color: #0d9bdb;
      transition-delay: .2s; }
    .timeline-horizontal__dots .slick-dots li.slick-active button {
      border-color: #0d9bdb;
      transition-delay: .2s; }
    .timeline-horizontal__dots .slick-dots li.slick-active button::after {
      background-color: #0d9bdb;
      transition-delay: .2s; }
    .timeline-horizontal__dots .slick-dots li.slick-active ~ li button {
      border-color: #b9e6fb;
      transition-delay: .2s; }

.timeline {
  position: relative;
  padding-left: 32px;
  /* this is the vertical line */ }
  @media only screen and (max-width: 740px) {
    .timeline {
      padding-left: 24px; } }
  .timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.075rem;
    background: #29b4f2; }
  .timeline__cursor {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }
  .timeline__cursor-line {
    position: absolute;
    top: 0.75rem;
    left: 0;
    width: 0.15rem;
    bottom: 0;
    transform: scaleY(0);
    background: #0d9bdb;
    margin-left: -0.05rem;
    transform-origin: top center;
    transition: transform 16ms ease;
    will-change: transform; }
  .timeline__cursor-dot {
    position: absolute;
    top: 0;
    left: -0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #0d9bdb;
    will-change: transform; }

.timeline__item {
  position: relative; }
  .timeline__item:not(:last-child) {
    margin-bottom: 4rem; }
  .timeline__item-content {
    align-items: center;
    display: flex; }
    @media only screen and (max-width: 740px) {
      .timeline__item-content {
        display: block; } }
  .timeline__item-content-cell {
    flex: 1; }
  .timeline__item-content-cell--image {
    text-align: center; }
  .timeline__item-dot {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.05rem solid #29b4f2;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    margin-left: -32px;
    background-color: #fff;
    z-index: 1; }
    @media only screen and (max-width: 740px) {
      .timeline__item-dot {
        margin-left: -24px; } }
    .timeline__item-dot:before {
      will-change: opacity;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      opacity: 0;
      border-radius: 50%;
      background: #0d9bdb;
      transform: translate(-50%, -50%);
      transition: opacity .2s ease-in-out; }
    .timeline__item-dot.is-active {
      border: 0.1rem solid #29b4f2; }
    .timeline__item-dot.is-active:before {
      opacity: 1; }
  .timeline__item:first-child .timeline__item-dot {
    top: 0;
    transform: translate(-50%, 0);
    border: 0.1rem solid #29b4f2; }
  .timeline__item:last-child .timeline__item-dot {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0); }

.timeline--middle {
  padding-left: 0; }
  @media only screen and (max-width: 740px) {
    .timeline--middle {
      padding-left: 24px; } }
  .timeline--middle:before,
  .timeline--middle .timeline__cursor,
  .timeline--middle .timeline__item-dot {
    left: 50%; }
    @media only screen and (max-width: 740px) {
      .timeline--middle:before,
      .timeline--middle .timeline__cursor,
      .timeline--middle .timeline__item-dot {
        left: 0; } }
  .timeline--middle .timeline__item-dot {
    margin-left: 0; }
    @media only screen and (max-width: 740px) {
      .timeline--middle .timeline__item-dot {
        margin-left: -24px; } }
  .timeline--middle .timeline__item:nth-child(even) .timeline__item-content-cell--text {
    order: -1; }

.timeline__item-content-cell--image > img {
  max-width: 100%; }

.timeline__item-content-cell--image {
  padding-right: 3rem; }
  @media only screen and (max-width: 740px) {
    .timeline__item-content-cell--image {
      padding-right: 0; } }

.timeline__item-content-cell--text {
  padding-left: 3rem; }
  @media only screen and (max-width: 740px) {
    .timeline__item-content-cell--text {
      padding-left: 0; } }

.timeline--middle .timeline__item:nth-child(even) .timeline__item-content-cell--image {
  padding-right: 0;
  padding-left: 3rem; }
  @media only screen and (max-width: 740px) {
    .timeline--middle .timeline__item:nth-child(even) .timeline__item-content-cell--image {
      padding-left: 0; } }

.timeline--middle .timeline__item:nth-child(even) .timeline__item-content-cell--text {
  padding-left: 0;
  padding-right: 3rem; }
  @media only screen and (max-width: 740px) {
    .timeline--middle .timeline__item:nth-child(even) .timeline__item-content-cell--text {
      padding-right: 0; } }

/*
 * A modern theme for portfolio pages using sans serif typefaces
 * and a modular scale with fluid type. This theme also includes a
 * dark variation that can be used to emphasize a section.
 */
/*
* Don’t import any other fonts here, because they would be loaded on every page.
* Ideally, themes could be included in the head of a page to avoid
* loading global styles. By design, this is not possible because the "meta" block
* in layout templates will inject custom meta tags, such as a custom CSS file,
* before other styles. Fonts are loaded from the page layout for now.

* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700');

*/
.theme--modern .narrative {
  font-size: 1em;
  /* Remove element specific spacing */
  /*
   * Tags
   */
  /*
   * Spacing
   */
  /* Specific styles for project title + subtitle combo */ }
  .theme--modern .narrative h1, .theme--modern .narrative h2, .theme--modern .narrative h3, .theme--modern .narrative h4, .theme--modern .narrative h5, .theme--modern .narrative h6, .theme--modern .narrative p, .theme--modern .narrative ul, .theme--modern .narrative li, .theme--modern .narrative blockquote {
    font-family: "Avenir Next", "Avenir", "Helvetica Neue", sans-serif; }
  .theme--modern .narrative h1, .theme--modern .narrative h2, .theme--modern .narrative h3, .theme--modern .narrative h4, .theme--modern .narrative h5, .theme--modern .narrative h6 {
    margin-top: 0; }
  .theme--modern .narrative h1 {
    font-size: 35.2512px;
    text-align: center;
    line-height: 1.25;
    margin-top: 15%;
    font-weight: 700; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern .narrative h1 {
        font-size: calc( 35.2512px + 24.53591 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern .narrative h1 {
        font-size: 59.78711px; } }
  .theme--modern .narrative h2 {
    font-size: 24.48px;
    font-weight: 700; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern .narrative h2 {
        font-size: calc( 24.48px + 8.325 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern .narrative h2 {
        font-size: 32.805px; } }
  .theme--modern .narrative h3 {
    font-size: 20.4px;
    font-weight: 600; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern .narrative h3 {
        font-size: calc( 20.4px + 3.9 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern .narrative h3 {
        font-size: 24.3px; } }
  .theme--modern .narrative h4 {
    font-size: 17px;
    font-weight: 600; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern .narrative h4 {
        font-size: calc( 17px + 1 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern .narrative h4 {
        font-size: 18px; } }
  .theme--modern .narrative p, .theme--modern .narrative ul, .theme--modern .narrative li, .theme--modern .narrative blockquote {
    font-size: 17px;
    line-height: 1.5; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern .narrative p, .theme--modern .narrative ul, .theme--modern .narrative li, .theme--modern .narrative blockquote {
        font-size: calc( 17px + 1 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern .narrative p, .theme--modern .narrative ul, .theme--modern .narrative li, .theme--modern .narrative blockquote {
        font-size: 18px; } }
  .theme--modern .narrative a {
    color: black;
    text-decoration: underline; }
  .theme--modern .narrative strong {
    font-weight: 600; }
  .theme--modern .narrative blockquote {
    border-left: 8px solid rgba(0, 0, 0, 0.1);
    margin: 1.5em 0 1.5em 0;
    padding-left: 1rem;
    opacity: 0.7; }
  .theme--modern .narrative figcaption {
    text-align: center; }
  .theme--modern .narrative hr {
    margin: 3rem 0; }
  .theme--modern .narrative h1 + h2 {
    margin-top: 0.5em;
    text-align: center;
    font-weight: normal;
    opacity: 0.5; }
  .theme--modern .narrative h2 + h3 {
    margin-top: 2rem; }
  .theme--modern .narrative figure + p,
  .theme--modern .narrative p + figure {
    margin-top: 1.5rem; }
  .theme--modern .narrative h3 + p,
  .theme--modern .narrative p + p {
    margin-top: 1em; }
  .theme--modern .narrative ul + h3,
  .theme--modern .narrative p + h3,
  .theme--modern .narrative figure + h3 {
    margin-top: 3rem; }

/* -------------------------------------------------------------------------- */
.theme--modern-dark .narrative {
  /*
   * Styles for a feature block that can be used to emphasize a section.
   * Applies a dark theme.
   */
  color: white;
  /* Control rendering for light text on dark background */
  -webkit-font-smoothing: antialiased;
  /*
   * Tags
   */
  /*
   * Spacing
   */
  /* Tweak for multi-column baseline alignment */
  /*
    * Mobile layout changes
    */ }
  .theme--modern-dark .narrative h2 {
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    text-align: left;
    font-size: 29.376px;
    color: white;
    opacity: 1; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .theme--modern-dark .narrative h2 {
        font-size: calc( 29.376px + 14.91075 * ( ( 100vw - 320px) / 880 )); } }
    @media (min-width: 1200px) {
      .theme--modern-dark .narrative h2 {
        font-size: 44.28675px; } }
  .theme--modern-dark .narrative p {
    opacity: 0.8;
    margin-top: 0; }
  .theme--modern-dark .narrative hr {
    background: rgba(255, 255, 255, 0.25);
    border: 0;
    display: block;
    width: 100%;
    height: 1px;
    margin: 40px 0;
    padding: 0; }
  .theme--modern-dark .narrative h4 + p {
    margin-top: 0.2rem; }
  @media (max-width: 480px) {
    .theme--modern-dark .narrative {
      /* Change side-by-side grid layout to a stack */ }
      .theme--modern-dark .narrative .l-grid {
        display: block; }
      .theme--modern-dark .narrative .l-one-half + .l-one-half {
        margin-top: 1.5rem; }
      .theme--modern-dark .narrative .l-one-half {
        width: 100%; } }

.align-center {
  text-align: center; }

video,
.shadow {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2), 0px 1px 4px rgba(0, 0, 0, 0.2); }

.l-page {
  margin-top: 60px; }
  .l-page:not(.l-page--spaceless) {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column; }
  .l-page__content {
    flex: 1; }
  @media only screen and (max-width: 740px) {
    .l-page {
      margin-top: 60px;
      min-height: auto; } }
  .l-page--spaceless {
    margin-top: 0; }
    @media only screen and (max-width: 740px) {
      .l-page--spaceless {
        margin-top: 0; } }

.video--no-shadow {
  box-shadow: none;
  justify-content: center; }

.download-badge {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  @media only screen and (max-width: 740px) {
    .download-badge {
      flex-direction: column; } }

.download-badge__button {
  height: 100px;
  display: block;
  margin-top: 4.5rem; }
  @media only screen and (max-width: 740px) {
    .download-badge__button {
      margin: 0;
      height: 80px; } }

.download-badge__legal-notice {
  font-family: Avenir, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.4;
  opacity: .5; }
  @media only screen and (max-width: 740px) {
    .download-badge__legal-notice {
      font-size: .7rem; } }

img.download-badge__img {
  height: 100%;
  width: auto;
  margin-top: 0; }
