$timeline-color: c(client, local);
$timeline-color-lighten: lighten(c(client, local), 40%);
$timeline-item-inner-spacings: 25px;
$timeline-dots-vertical-spacing: 50px;
$timeline-dots-dimention: 30px;
$timeline-dots-inner-sphere-spacing: 4px;
$timeline-dots-border-width: 2px;
$timeline-dots-line-width: 2px;

.timeline-horizontal {
  padding: 0;
}

.timeline-horizontal__item {
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  outline: none;

  & > * {
    &:not(:last-child) {
      margin-right: $timeline-item-inner-spacings;
    }
  }
}

.timeline-horizontal__dots {
  position: relative;
  padding: $timeline-dots-vertical-spacing 0;
  overflow: hidden;

  // Remove ul default padding-start
  ul {
    padding: 0;
  }
}

.timeline-horizontal__dots-line {
  position: absolute;
  z-index: 0;
  content: "";
  height: $timeline-dots-line-width;
  width: 0;
  top: 50%;
  left: 0;

  background-color: $timeline-color;

  transition: width .2s ease-in-out;
}

.timeline-horizontal__dots::before {
  position: absolute;
  z-index: 0;
  content: "";
  height: $timeline-dots-line-width;
  width: 100%;
  top: 50%;
  left: 0;

  background-color: $timeline-color-lighten;
}

.timeline-horizontal__dots .slick-dots {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  * {
    outline: none;
  }

  li {
    display: inline;
    position: relative;
    height: $timeline-dots-dimention;

    // Sphere border
    button {
      // Reset default button styles
      color: transparent;
      border: none;

      position: relative;
      z-index: 1;

      background: white;
      border-radius: 100%;
      border-color: $timeline-color;
      border-style: solid;
      border-width: $timeline-dots-border-width;
      height: 100%;
      width: $timeline-dots-dimention;
      cursor: pointer;

      transition: border-color .2s ease-in-out;

      // Sphere circle
      &::after {
        position: absolute;
        content: "";
        background-color: transparent;
        border-radius: 100%;
        top: $timeline-dots-inner-sphere-spacing;
        bottom: $timeline-dots-inner-sphere-spacing;
        left: $timeline-dots-inner-sphere-spacing;
        right: $timeline-dots-inner-sphere-spacing;

        transition: background-color .2s ease-in-out;
      }
    }

    // Highlight the sphere border on hover
    &:hover button {
      border-color: $timeline-color;
      transition-delay: .2s;
    }

    &.slick-active {
      // Highlight the current selected sphere border (also on hover)
      button {
        border-color: $timeline-color;
        transition-delay: .2s;
      }

      // Highlight the current sphere circle
      button::after {
        background-color: $timeline-color;
        transition-delay: .2s;
      }

      // Unhighlight all the following sphere border
      & ~ li button {
        border-color: $timeline-color-lighten;
        transition-delay: .2s;
      }
    }
  }
}
