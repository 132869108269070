$l-container-width: 1200px;

.l-container {
  max-width: $l-container-width;
  margin: 0 auto;
  padding: 0 16px;
}

.l-container--medium {
  max-width: 800px;
}

.l-container--small {
  max-width: 680px;
}


.l-container--spaceless {
  padding-left: 0;
  padding-right: 0;
}

.l-container--left-aligned {
  margin-left: 0;
}
