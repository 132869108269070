/**
 * Color variables
 */
$colors: (
  ginetta: (
    pink: #fb9fb1,
    salmon: #f0a88b,
    orange: #e6ad7a,
    green-olive: #a4c46a,
    green: #73cb88,
    green-lake: #55ce9b,
    teal: #34cfad,
    cyan: #0fcdd0,
    blue: #5ec5eb,
    blue-sky: #80bff4,
    purple: #9eb8f8,
    violet: #d0a9f6,
  ),
  client: (
    aroov: #32b5dc,
    aroov-text: #4da5ca,
    avaloq: #003076,
    basler-hofmann: #dc2856,
    bh-black: #2f2f2f,
    comet: #48646d,
    crowdhouse: #5fb6cb,
    crowdhouse-dark: #008092,
    crowdhouse-light: #ebf2f2,
    docsafe: #1781e3,
    docsafe-grey: #2c2723,
    doodle: #0060df,
    fantoche: #5eaeaa,
    filmcoopi: #fff507,
    helsana: #9c0240,
    helsana-gold: #ddc48c,
    endress: #007caa,
    endress-magenta: #a8005c,
    hilti: #d0021b,
    hilti-website: #d2071e,
    lizzy: #e9eaf0,
    lizzy-purple: #00074e,
    lizzy-pink: #e61b58,
    lizzy-darkgrey: #8f909b,
    lizzy-blue: #006ce2,
    local: #0d9bdb,
    migros: #ff6600,
    mobility: #c31622,
    puma: #00853f,
    puma-green: #006e34,
    nzz: #3b8fc9,
    paycall: #42414d,
    paycall-violet: #4d4974,
    postfinance: #f8cf34,
    postfinanceapp: #00545E,
    raiffeisen: #c84a48,
    sbb: #f10000,
    siroop: #41cadf,
    siroop-grey: #f1f5f7,
    swisscom: #000d57,
    sympany: #ca0018,
    sympany-light: #fdf7f8,
    uepaa: #0e8bcf,
    testingtime: #84c35a,
    twint-dark: #1d262c,
    twint-grey: #f3f3f3,
    twint-blue: #50a4d3,
    zurich: #0c2e96,
    bfu: #00b2aa,
    jutzler: #2f80ed,
    vontobel: #ffd500,
  ),
  ui: (
    link: cornflowerblue,
  ),
  neutral: (
    black: #16161d,
    graphite: #666,
    gray: #ddd,
    silver: #fafafa,
    white: #fff,
  ),
);

/**
 * Color functions
 */
@function c($name, $lightness: normal) {
  @if map-has-key($colors, $name) {
    $color-variants: map-get($colors, $name);
    @if map-has-key($color-variants, $lightness) {
      @return map-get($color-variants, $lightness);
    }
  }
  @warn 'no color named #{$name} in $colors';
  @return null;
}

/**
 * Color classes
 * Try to use only in the styleguide
 */
@each $color, $variants in $colors {
  @each $variant, $value in $variants {
    // Remove the 'normal' key for default colors
    $variantName: -#{$variant};
    @if $variant == "normal" {
      $variantName: "";
    }

    .c-#{$color}#{$variantName} {
      background-color: #{$value};

      @if $color == "client" {
        color: rgba(c(neutral, white), 0.95);
      }
    }

    .c-#{$color}#{$variantName}--fill {
      fill: #{$value};
    }
    .c-#{$color}#{$variantName}--fill-dark {
      fill: #{darken($value, 10%)};
    }

    .c-#{$color}#{$variantName}--color {
      color: #{$value};
    }
  }
}

/*
   Hack to overcome the lack of color contrast check
 */
.c-client-siroop-grey {
  color: rgba(0, 0, 0, 0.8);
}

.c-client-filmcoopi {
  color: rgba(0, 0, 0, 1);
}

.color-aroov,
.c-color-aroov--color blockquote {
  color: #32b5dc;
}
