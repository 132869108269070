// [1] vertically center image
.header-minimal {
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;

  padding-top: s(x-large);
  position: relative;
  padding-top: s(x-large) + $header-height;
  @include breakpoint(mobile) {
    padding-top: s(x-large) + $header-height-mobile;
    height: 80vh !important;
  }


  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__image {
    transform: translate3d(0, 0, 0);
  }

  &__title {
    text-align: center;
    transform: translate3d(0, 0, 0);
    padding-bottom: s(large);
    @include breakpoint(mobile) {
      transform: none !important;
      opacity: 1 !important;
    }
  }

  &__image-abs {
    position: absolute;
    max-width: 75%;
    backface-visibility: hidden;
    will-change: transform;
    transform: translateZ(0);
    &:not(&--manual-space-mobile) {

      @include breakpoint(mobile) {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        top: auto !important;
        bottom: 0 !important;
        left: 50% !important;
        right: auto !important;

        transform-origin: center center;

        transform: translateX(-50%) !important;

        &.header-minimal__image-abs--space-on-mobile {
          top: 50% !important;
          bottom: auto !important;
          transform: translate(-50%, -50%) !important;
        }
      }
    }

    &--manual-space-mobile {
      @include breakpoint(mobile) {
        max-width: 100%;
      }
    }

    &.is-centered {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__images {
    position: relative;
    flex-grow: 1;
    @include breakpoint(mobile) {
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &__title,
  &__image-abs {
    // will-change: transform, opacity;
    // transition-delay: 16ms;
    // transition-timing-function: ease-in-out;
  }

  &__image {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
  }
}


// @TODO
.header-minimal--image-right {
  flex-direction: row;
}


.header-minimal--centered-image .header-minimal__image {
  align-self: center;
  padding-bottom: 2rem;
}


.header-minimal--has-abs-images {
  overflow: hidden;
}
