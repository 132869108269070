$vertical-spacing: 10px;
$horizontal-spacing: 25px;
$bg-color-hover-lighten: 20%;
$bg-color-disable-lighten: 20%;
$color-disable-darken: 20%;

.button {
  display: inline-block;
  padding: $vertical-spacing $horizontal-spacing;
  background: c(neutral, black);

  // Reset default styles
  border: none;
  outline: none;
  user-select: none;

  color: c(neutral, white);
  text-align: center;
  cursor: pointer;

  transition: background-color .15s ease-in-out;

  &:hover {
    background-color: lighten(c(neutral, black), $bg-color-hover-lighten);
  }

  &[disable] {
    color: darken(c(neutral, white), $color-disable-darken);
    background-color: lighten(c(neutral, black), $bg-color-disable-lighten);
    pointer-events: none;
  }
}
