@import "sensible/mediaqueries";

.l-grid-hipster {
  &__row {
    display: flex;
    flex-flow: row wrap;
    &--vertical {
      flex-direction: column;
    }
  }
  &__cell {
    flex: 1;
    background: rgba(red, 0.1);
  }
}


@include breakpoint(mobile) {
  .l-grid-hipster {
    &__row {
      display: block;
    }
  }
}
