.callout {
  text-align: center;
  margin: 0;
  background-size: cover;
  &:after {
    content: close-quote;
  }
  &:before {
    content: open-quote;
  }
}
