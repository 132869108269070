$avatar-background-color: c(neutral, silver);

$avatar-sizes: (
  small: 120px,
  medium : 160px,
  large: 190px
);

/**
 * Sizes function
 * Expects a size name (default: medium) and returns it's value in rem
 */
@function avatarSize($name: medium) {
  @if map-has-key($avatar-sizes, $name) {
    @return map-get($avatar-sizes, $name);
  }

  @warn "Unknown `#{$name}` in $avatar-sizes.";
  @return null;
}

/* Helper for avatar-sizes */
@each $avatar-size, $value in $avatar-sizes {
  .avatar--#{$avatar-size} {
    width: avatarSize($avatar-size);
    height: avatarSize($avatar-size);
  }
}

.avatar {
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;

  &__image {
    position: absolute;
    width: auto;
    transform: translateX(-50%);
    width: auto;
    max-height: 100%;
    border-radius: 50%;
    background: $avatar-background-color;
  }
}
