$carousel-width: 80%;
$carousel-neighbour-slides-opacity: 0.3;
$carousel-neighbour-slides-scaling: 0.8;
$carousel-center-slide-opacity: 1;
$carousel-center-slide-scaling: 1;
$carousel-slick-slide-transition: transform .3s ease-out;
$carousel-button-color: c(neutral, graphite);
$carousel-button-background-color: c(neutral, white);
$carousel-button-radius: 4rem;


$carousel-button-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
$carousel-button-shadow-hover: 0 0 0.5rem rgba(0, 0, 0, 0.6);

$carousel-button-position: 2rem;
$carousel-button-arrow-size: $carousel-button-radius /4;
$carousel-button-arrow-thickness: 0.05rem;
$carousel-distance-to-center-slide: 1.5rem;

$carousel-button-border-thickness: 1px;
$carousel-button-size: 10px;
$carousel-button-space-between: 8px;

$carousel-footer-mobile-height: 30px;
$carousel-footer-mobile-spacing: 16px;



@mixin hide-text {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

.carousel {
  // width: $carousel-width;
  // margin: 0 auto;
  position: relative;
}

.carousel__button {
  position: absolute;
  top: 50%;
  width: $carousel-button-radius;
  height: $carousel-button-radius;
  border: 0;
  border-radius: 50%;
  background: $carousel-button-background-color;
  box-shadow: $carousel-button-shadow;
  @include hide-text;
  cursor: pointer;
  z-index: 2;
  outline: none;

  transition: box-shadow .1s linear, transform .1s;
  transform: translateY(-50%) scale(1);


  &:active {
    transform: translateY(-50%) scale(0.95);
  }

  &:hover {
    opacity: 0.9;
  }


  &--prev {
    left: 20px;

    &:before {
      transition: transform 0.2s;
      transform: translate(3px, 0px) rotate(-135deg);
    }
  }

  &--next {
    right: 20px;

    &:before {
      transform: translate(-3px, 0px) rotate(45deg) ;
    }
  }


  &:before {
    content: "";
    display: inline-block;
    width: $carousel-button-arrow-size;
    height: $carousel-button-arrow-size;
    border-right: $carousel-button-arrow-thickness solid $carousel-button-color;
    border-top: $carousel-button-arrow-thickness solid $carousel-button-color;
  }

  &:hover, &:focus {
    background: $carousel-button-background-color;
    box-shadow: $carousel-button-shadow-hover;
  }

  @include breakpoint(mobile) {
    display: none;
  }
}

.carousel__item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  padding-left: $carousel-distance-to-center-slide;
  padding-right: $carousel-distance-to-center-slide;
  opacity: .2;
  transition: opacity .2s ease-in-out;
  outline: none;
  width: $l-container-width;

  &.slick-current,
  &:focus {
    opacity: 1;
  }

  @include breakpoint(mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}

.carousel__image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 1rem;
}


.carousel__dots {
  height: $carousel-footer-mobile-height;
  padding: $carousel-footer-mobile-spacing ($carousel-footer-mobile-height + 2 * $carousel-footer-mobile-height);
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: center;

  // [1] reset button stuling
  button {
    @include hide-text;
    background: transparent; // [1]
    outline: none;           // [1]
    padding: 0;              // [1]
    cursor: pointer;
    border-radius: 50%;
    color: inherit;

    border: $carousel-button-border-thickness solid currentColor;
    width: $carousel-button-size;
    height: $carousel-button-size;


    &:hover {
      background-color: currentColor;
    }
  }

  > li.slick-active button {
    background-color: currentColor;
  }

  > li:not(:first-child) {
    margin-left: $carousel-button-space-between;
  }
}
