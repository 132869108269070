$sizes: (
  huge        : 136px,
  x-large     : 64px,
  large       : 32px,
  medium      : 24px,
  normal      : 16px,
  small       : 12px,
  tiny        : 8px
);

/**
 * Sizes function
 * Expects a size name (default: medium) and returns it's value in rem
 */
@function s($name: medium) {
  @if map-has-key($sizes, $name) {
    @return map-get($sizes, $name);
  }

  @warn "Unknown `#{$name}` in $sizes.";
  @return null;
}

/* Helpers for the styleguide to display the sizes */
@each $size, $value in $sizes {
  .s-#{$size} {
    width: s($size);
    height: s($size);
  }

  .s-inner-#{$size} {
    padding: s($size);
  }

  .s-vertical-#{$size} {
    padding-top: s($size);
    padding-bottom: s($size);
  }
}


.d-b { display: block; }