
@import "bonescss/mixins";

$t-weight-regular              : 400;
$t-weight-bold                 : 800;

$t-style-normal         : normal;
$t-style-italic         : italic;

$t-family-sans-serif    : Avenir, Arial, sans-serif;
$t-family-serif         : 'PT Serif', serif;

$t-default-font-size    : 20px;
$t-default-font-family  : $t-family-sans-serif;
$t-default-font-weight  : $t-weight-regular;
$t-default-font-style   : $t-style-normal;
$t-default-line-height  : 1.4;
$t-default-color        : c(neutral, black);

$types: (
  desktop: (
    default: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : $t-default-font-size,
      line-height       : $t-default-line-height,
      color             : $t-default-color
    ),
    project-title: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-bold,
      font-style        : $t-style-normal,
      font-size         : 2.8rem,
      line-height       : 1.14,

    ),
    project-lead: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-bold,
      font-style        : $t-style-normal,
      font-size         : 2.1rem,
      line-height       : 1.19,
    ),
    section-title: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-italic,
      font-size         : .9rem,
      line-height       : 1.5,
      letter-spacing    : 4px,
      text-transform    : uppercase
    ),
    title: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-bold,
      font-style        : $t-style-normal,
      font-size         : 2rem,
      line-height       : 1.3,
    ),
    lead: (
      font-family       : $t-family-serif,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : 1.2rem,
      line-height       : 1.65,
    ),
    subtitle: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-bold,
      font-style        : $t-style-normal,
      font-size         : 1.3rem,
      line-height       : 1.3,
    ),
    h4: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-bold,
      font-style        : $t-style-normal,
      font-size         : 1.1rem,
      line-height       : 1.3,
    ),
    paragraph: (
      font-family       : $t-family-serif,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : 1rem,
      line-height       : 1.65
    ),
    paragraph-sans: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : 1rem,
      line-height       : 1.65
    ),
    caption: (
      font-family       : $t-default-font-family,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : 0.8rem,
      line-height       : 1.4
    ),
    quote: (
      font-family       : $t-family-serif,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-italic,
      font-size         : 1.8rem,
      line-height       : 1.4,
    ),
    quote-small: (
      font-family       : $t-family-serif,
      font-weight       : $t-weight-regular,
      font-style        : $t-style-normal,
      font-size         : 1.5rem,
      line-height       : 1.6,
    )
  ),
  mobile: (
    default: (
    ),
    project-title: (
      font-size: 2rem
    ),
    project-lead: (),
    section-title: (
      font-size: .8rem,
    ),
    title: (
      font-size: 1.5rem
    ),
    lead: (
      font-size: 1rem
    ),
    subtitle: (),
    paragraph: (
      font-size: 0.9rem
    ),
    caption: (),
    quote: (
      font-size: 1.5rem
    ),
    quote-small: ()
  )
);

html {
  font-size: $t-default-font-size;
  font-family: $t-default-font-family;
  font-weight: $t-default-font-weight;
  font-style: $t-default-font-style;
  line-height: $t-default-line-height;
  color: c(neutral, black);
  // -webkit-font-smoothing: antialiased;
}

$breakpoint-default: desktop;

// renders default text helper classes
@include textClasses ($prefix: ".t-");

@font-face {
  font-family: Avenir;
  font-weight: $t-weight-regular;
  font-style: normal;
  src: url("//static.ginetta.net/fonts/avenir-roman/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.eot");
  src: local('Avenir Roman'),
    url("//static.ginetta.net/fonts/avenir-roman/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.woff") format("woff"),
    url("//static.ginetta.net/fonts/avenir-roman/5ed4f98e-9044-4810-88ff-74d412c1351f.ttf") format("truetype"),
    url("//static.ginetta.net/fonts/avenir-roman/4cb16859-16ca-4342-b89c-292dc83266af.svg") format("svg");
}

@font-face {
  font-family: Avenir;
  font-weight: $t-weight-regular;
  font-style: italic;
  src: url("static.ginetta.net/fonts/avenir-black-oblique/8f21e618-9282-4df1-b556-73ee82bdd673.eot");
  src: local("Avenir Oblique"),
      url("static.ginetta.net/fonts/avenir-black-oblique/3695342c-b5e2-4010-b0d4-56f563465922.woff") format("woff"),
      url("static.ginetta.net/fonts/avenir-black-oblique/2ba9f875-66d9-414d-a426-5a012b443475.ttf") format("truetype"),
      url("static.ginetta.net/fonts/avenir-black-oblique/0a88351a-e628-4b1f-99eb-3a729518af0a.svg") format("svg");
}


@font-face {
  font-family: Avenir;
  font-weight: $t-weight-bold;
  font-style: normal;
  src: url("//static.ginetta.net/fonts/avenir-heavy/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot");
  src: local('Avenir Heavy'),
    url("//static.ginetta.net/fonts/avenir-heavy/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff") format("woff"),
    url("//static.ginetta.net/fonts/avenir-heavy/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf") format("truetype"),
    url("//static.ginetta.net/fonts/avenir-heavy/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg") format("svg");
}

// We don't have this
// @font-face {
//   font-family: Avenir;
//   font-weight: $t-weight-bold;
//   font-style: italic;
//   src: url("//static.ginetta.net/fonts/avenir-black-oblique/fbf3e69b-73b2-406d-84bc-feda30a0a563.eot?#iefix");
//   src: local('Avenir Black Oblique'),
//       url("//static.ginetta.net/fonts/avenir-black-oblique/36ec15b9-f8da-447f-8fed-a9cfdfb91fbb.woff") format("woff"),
//       url("//static.ginetta.net/fonts/avenir-black-oblique/230d6b14-6d44-4dd5-a6ae-a4287936e51e.ttf") format("truetype"),
//       url("//static.ginetta.net/fonts/avenir-black-oblique/9bce4920-94e2-4e4d-bd34-818801dd3eb3.svg#9bce4920-94e2-4e4d-bd34-818801dd3eb3") format("svg");
// }

//  TODO: change to javascript import
@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400italic,700,700italic);


h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0;
}


strong,
.t-bold {
  font-weight: 800;
}

.t-centered {
  text-align: center;
}

.t-smooth {
  -webkit-font-smoothing: antialiased;
}

.t-sans {
  font-family: $t-family-sans-serif;
}
