/* Multiple box shadows to achieve the glass lens effect */
$screenshot-magnifying-lens-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 0 0 7px 7px rgba(0, 0, 0, 0.25), inset 0 0 40px 2px rgba(0, 0, 0, 0.25);

/* Lens */
$screenshot-magnifying-lens-size: 10rem;

/* Text while loading */
$screenshot-magnifying-loading-color: c(neutral, white);
$screenshot-magnifying-loading-background: c(neutral, black);
$screenshot-magnifying-loading-content: 'Loading...';
$screenshot-magnifying-text-shadow: 0 0 2px rgba(51, 51, 51, 0.8);
$screenshot-magnifying-loading-content-top: 45%;


.screenshot-magnifying {
  text-align: center;
}


html.magnifying > body {
  overflow-x: hidden;
}

.magnify {
  position: relative;
  display: inline-block;
}
.magnify-lens {
  position: absolute;
  width: $screenshot-magnifying-lens-size;
  height: $screenshot-magnifying-lens-size;
  border-radius: 100%;
  box-shadow: $screenshot-magnifying-lens-box-shadow;
  cursor: none;
  display: none;
  z-index: 2;
}
.magnify-lens.loading {
  background: $screenshot-magnifying-loading-background !important;
  opacity: 0.75;
}
.magnify-lens.loading:after {
  position: absolute;
  top: $screenshot-magnifying-loading-content-top;
  left: 0;
  width: 100%;
  color: $screenshot-magnifying-loading-color;
  content: $screenshot-magnifying-loading-content;
  text-align: center;
  text-shadow: $screenshot-magnifying-text-shadow;
}
