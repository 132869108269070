.ratio {
  display: block;
  position: relative;
  &--panorama {
    padding-top: 50%;
  }

  &--1to4 {
    padding-top: (1 / 4) * 100%;
  }

  &--square {
    padding-top: 100%;
    @include breakpoint(mobile) {
      padding-top: 50%;
    }
  }

  &--3to2 {
    padding-top: (2/3) * 100%;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
