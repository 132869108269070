@import "bonescss/mixins";


.narrative {

  img,
  video {
    max-width: 100%;
  }

  h1:not([class]) {
    @include type(project-title);
  }

  h2:not([class]) {
    @include type(title);
  }


  h3:not([class]) {
    @include type(subtitle);
  }

  h4:not([class]) {
    @include type(h4);
  }

  h5:not([class]),
  h6:not([class]) {
    @include type(lead);
  }

  p:not([class]),
  li {
    @include type(paragraph);
  }

  figcaption:not([class]) {
    @include type(caption);
    color: c(neutral, graphite);
  }

  table {
    @include type(paragraph);
  }

  a {
    color: c(ui, link);
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  figure {
    margin: 0;
  }

  figure + figure {
    margin-top: 3rem;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    // max-width: 36rem;
  }

  small {
    font-size: 90%;
  }

  img {
    max-width: 100%;
    // center image
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


  figure,
  table,
  figcaption,
  ul,
  ol,
  p {
    margin-top: 1em;
  }

  li {
    margin-top: .5em;
  }

  img,
  video {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1.5em;
  }

  h2 + p {
    margin-top: 2em;
    // color: rebeccapurple;
  }

  figcaption {
    margin-bottom: 2em;
  }

  td {
    padding-top: 1.6em,
  }


  > *:first-child {
    margin-top: 0;
  }

  td {
    vertical-align: top;
  }

  .timeline {
    margin-top: 4rem;
  }

  .no-vr {
    margin-top: 0;
  }
}
