$vertical-padding: s(medium);
$horizontal-padding: s(medium);
$title-bottom-spacing: s(small);

.image-link {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  backface-visibility: hidden;

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $vertical-padding $horizontal-padding;
    z-index: 2;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .3;
    background-color: currentColor;
    transition: 0.2s opacity, transform 0.3s ease-in-out;
    will-change: opacity, transform;
    transform: translate3d(0, 0, 0) scaleY(1);
    transform-origin: center center;
  }

  &__title {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 34px, 0);;
    will-change: transform, transform;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }


  &__subtitle {
    margin-top: s(tiny);
    opacity: 0;
    transform: translate3d(0, 34px, 0);
    will-change: opacity, transform;
    transition: opacity 0.2s, transform 0.35s cubic-bezier(.13, 1, .4, 1);
    line-height: 34px;
  }

  &:hover &__overlay {
    opacity: 0;
    transform: translate3d(0, -34px, 0) scaleY(0);
    transition: opacity .3s, transform 0.5s ease-in-out;
  }

  &:hover &__title,
  &:hover &__subtitle {
    transform: translate3d(0, 0, 0);
  }

  &:hover &__title {
    transition: transform 0.35s;
  }

  &:hover &__subtitle {
    opacity: 1;
    transition-delay: 0.15s;
    transition-duration: 0.35s;
  }
}
