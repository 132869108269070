$card-subtitle-height: 22px;
$card-divider-width: 48px;

.card {
  display: block;
  position: relative;
  background-color: c(neutral, silver);
  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;


    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.0980392))
  }

  &__logo {
    max-width: 130px;
    display: none;
  }

  &__divider {
    position: absolute;
    top: 50%;
    left: calc(50% - #{$card-divider-width} / 2);
    width: $card-divider-width;
    margin-top: .65rem;
    border: 0;
    border-bottom: 1px solid white;
    opacity: 0;
    transform: scaleX(0);
    transition: transform .2s, opacity .2s;
  }

  &__footer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: s(small) s(medium);
  }

  &__title,
  &__subtitle {
    display: block;
    text-align: center;
  }

  &__title {
    color: c(neutral, white);
    font-weight: $t-weight-bold;
    // transform: translateY($card-subtitle-height);
    transition: 200ms transform;
  }

  &__subtitle {
    height: $card-subtitle-height;
    color: c(neutral, white);
    // opacity: 0;
    // transform: translateY(100%);
    transition: 200ms opacity, 200ms transform;
    margin-top: s(small);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: c(neutral, black);
    opacity: .8;
    transform: translate3d(0, 0, 0);
    will-change: opacity;
    transition: 200ms opacity, 100ms background-color;
  }

  &__content {
    z-index: 2;
  }

  &:hover &__title {
    transform: translateY(-10px);
  }

  &:hover &__subtitle {
    opacity: 1;
    transform: translateY(10px);
  }

  &:hover &__divider {
    opacity: 1;
    transform: scaleX(1);
  }

  &:hover &__overlay {
    opacity: .9;
  }
}
